import React, { useEffect, useState } from 'react';
import Selection from '../../selection';

function Employed(props) {
    const [display, setDisplay] = useState(null)

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case 8:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "Employment status",
                        content: "Lenders need to know this so they can work out how much they will lend you.",
                        buttons: {
                            "Permanent contract": {
                                value: "PERMANENT",
                                next: 9
                            },
                            "Fixed term contract": {
                                value: "FIXED_TERM",
                                next: 9
                            },
                            "Subcontractor (fixed term)": {
                                value: "SUBCONTRACTOR_FIXED_TERM",
                                next: 9
                            },
                            "Subcontractor (open ended)": {
                                value: "SUBCONTRACTOR_OPEN_ENDED",
                                next: 9
                            },
                            "Temporary contract": {
                                value: "TEMPORARY",
                                next: 9
                            },
                        },
                    }} id={"employment_contract"} setValue={(e) => props.setValue({ ...props.store, employment_contract: e })} />
                )

            case 9:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "What's your annual income?",
                        content: "This is before tax, and excluding any  bonuses, commission, or overtime.",
                        input: {
                            type: "number",
                            placeholder: "10000",
                            id: "annual_income",
                            min: 10000,
                            next: 10
                        }
                    }} id={"annual_income"} setValue={(e) => props.setValue({ ...props.store, annual_income: e })} />
                )

            case 10:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "Are there any monthly deductions included on your payslip?",
                        content: "This includes monthly childcare vouchers, student loan repayments, season ticket loan repayments, or other deductions after tax.",
                        input: {
                            type: "number",
                            placeholder: "10000",
                            id: "deductions",
                            next: 22
                        }
                    }} id={"deductions"} setValue={(e) => props.setValue({ ...props.store, deductions: e })} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default Employed;