import React, { useEffect, useState } from 'react';
import Selection from '../../selection';

function SecuredPayment(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        if (props.page === 39 && props.store.missed_secured_payment_date !== undefined && props.store.missed_secured_payment_amount !== undefined) {
            let missed_secured_payment_date = props.store.missed_secured_payment_date
            let missed_secured_payment_amount = props.store.missed_secured_payment_amount
            delete props.store.missed_secured_payment_date
            delete props.store.missed_secured_payment_amount
            props.setValue({ ...props.store, missed_secured_payments: [...props.store.missed_secured_payments, {missed_secured_payment_date: missed_secured_payment_date, missed_secured_payment_amount: missed_secured_payment_amount }]})
        }
        setDisplay(displayPage())
    }, [props.page, props.store])

    function remove_missed_secured_payment(i) {
        props.store.missed_secured_payments.splice(i, 1)
        props.setValue({...props.store, missed_secured_payments: props.store.missed_secured_payments})
    }

    function displayPage() {
        switch (props.page) {
            case 36:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "Have you ever missed a secured payment?",
                        info: {
                            id: "secured_payment_loan_modal",
                            text: "What is a secured payment?",
                            content: "A secured late payment relates to a mortgage or a secured loan that has been secured against assets, such as your home or car. If you fail to make payments, a creditor can seize the assets that the debt has been secured against.",
                        },
                        buttons: {
                            "Yes": {
                                value: "YES",
                                next: 37
                            },
                            "No": {
                                value: "NO",
                                next: 40
                            }
                        }
                    }} id={"had_a_missed_secured_payment"} setValue={(e) => props.setValue({ ...props.store, had_a_missed_secured_payment: e, missed_secured_payments: [] })} />
                )

            case 37:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "When did you last miss a secured payment?",
                        input: {
                            type: "month",
                            id: "missed_secured_payment_date",
                            min: 7,
                            next: 38
                        }
                    }} id={"missed_secured_payment_date"} setValue={(e) => props.setValue({ ...props.store, missed_secured_payment_date: e })} />
                )

            case 38:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "How much was the secured payment for?",
                        input: {
                            type: "number",
                            placeholder: "10000",
                            id: "missed_secured_payment_amount",
                            next: 39
                        }
                    }} id={"missed_secured_payment_amount"} setValue={(e) => props.setValue({ ...props.store, missed_secured_payment_amount: e })} />
                )

            case 39:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "Please list all missed secured payments since May 2019",
                        info: {
                            id: "secured_payment_loan_modal_2",
                            text: "What is a secured payment?",
                            content: "A secured late payment relates to a mortgage or a secured loan that has been secured against assets, such as your home or car. If you fail to make payments, a creditor can seize the assets that the debt has been secured against.",
                        },
                        collection: {
                            type: "missed_secured_payment",
                            add: 37
                        },
                        next: 40
                    }} id={"missed_secured_payment_collection"} setValue={(e) => {}} remove={(e) => remove_missed_secured_payment(e)} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default SecuredPayment;