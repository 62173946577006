import React, { useEffect, useState } from 'react'
import "./toggle.sass"

function Toggle(props) {
    
    const [degree, setDegree] = useState("0px")

    useEffect(() => {
        let values = {
            95: "109px",
            90: "151px",
            85: "190px",
            80: "232px",
            75: "272px",
            70: "100%"
        }
        console.log(props.value)
        setDegree(values[parseInt(props.value)] ? values[parseInt(props.value)] : "0px")
    }, [props.value])

    return (
        <div className="toggle" style={{ background: `linear-gradient(90deg, #5e00fa ${degree}, white ${degree})` }}>
            <div className="toggle_key" style={{color: props.value ? "white" : ""}}>
                {Object.keys(props.options)[0]}
            </div>
            {Object.entries(props.options["Expected deposit"])[0][1].map(e => {
                return (
                    <div className="toggle_value" onClick={() => props.addOption({"LTV": e + "%"})} style={{color: e >= parseInt(props.value) ? "white" : ""}}>
                        {100 - e + "%"}
                    </div>
                )
            })}
        </div>
    );
}

export default Toggle;