import React, { useEffect, useState } from 'react';
import Selection from '../../selection';

function SecondApplicant(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case 54:
                return (
                    <span>
                        <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                            heading: "What's the other applicant's name?",
                            content: "We also need to understand a few details about the other applicant.",
                            input: {
                                type: "text",
                                id: "first_name_2",
                                placeholder: "First name",
                                next: 55,
                                min: 1,
                                button: false
                            }
                        }} id={"first_name_2"} setValue={(e) => props.setValue({ ...props.store, first_name_2: e })} />
                        <div style={{ marginTop: "-15px" }} />
                        <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                            input: {
                                type: "text",
                                id: "last_name_2",
                                placeholder: "Last name",
                                next: 55,
                                min: 1,
                                focus: false
                            }
                        }} id={"last_name_2"} setValue={(e) => props.setValue({ ...props.store, last_name_2: e })} />
                    </span>
                )

            case 55:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "When is " + props.store.first_name_2 + "'s date of birth?",
                        content: "This helps us gauge the length of mortgages lenders can offer you.",
                        content2: "(Okay, we might send them a card too).",
                        input: {
                            type: "date",
                            id: "dob",
                            next: 56,
                            min: 10
                        }
                    }} id={"date_of_birth_2"} setValue={(e) => props.setValue({ ...props.store, date_of_birth_2: e })} />
                )

            case 56:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "How many dependents does " + props.store.first_name_2 + " have?",
                        content: "This includes any children or financially dependent adults over the age of 17 (please don't include any other applicants!)",
                        buttons: {
                            "0": 0,
                            "1": 1,
                            "2": 2,
                            "3": 3,
                            accordion: {
                                id: "dependants_accordion",
                                phrase: "More options",
                                alt: "Less options",
                                buttons: {
                                    "4": 4,
                                    "5": 5,
                                    "6": 6,
                                    "7": 7,
                                    "8": 8,
                                    "9": 9
                                }
                            }
                        },
                        next: 57
                    }} id={"dependants_2"} setValue={(e) => props.setValue({ ...props.store, dependants_2: e })} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default SecondApplicant;