import React, { useEffect, useState } from 'react';
import Selection from '../../../selection';

function SelfEmployed2(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case 61:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "How is " + props.store.first_name_2 + " self employed?",
                        buttons: {
                            "As a sole trader": {
                                value: "SOLE_TRADER",
                                next: 62
                            },
                            "In a partnership": {
                                value: "PARTNERSHIP",
                                next: 62
                            }
                        }
                    }} id={"self_employment_2"} setValue={(e) => props.setValue({ ...props.store, self_employment_2: e })} />
                )

            case 62:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "How many years has " + props.store.first_name_2 + " been in business?",
                        input: {
                            type: "years",
                            placeholder: "0",
                            id: "self_employed_years_in_business_2",
                            min: 1,
                            next: 63
                        }
                    }} id={"self_employed_years_in_business_2"} setValue={(e) => props.setValue({ ...props.store, self_employed_years_in_business_2: e })} />
                )

            case 63:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "What was " + props.store.first_name_2 + "'s annual net profit for 2019–2020?",
                        content: "This is after any business expenses, before tax, for the 2019-2020 tax year.",
                        input: {
                            type: "number",
                            placeholder: "0",
                            id: "self_employed_net_profit_19_20_2",
                            min: 10000,
                            next: 64
                        }
                    }} id={"self_employed_net_profit_19_20_2"} setValue={(e) => props.setValue({ ...props.store, self_employed_net_profit_19_20_2: e })} />
                )

            case 64:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "What was " + props.store.first_name_2 + "'s annual net profit for 2018–2019?",
                        content: "This is after any business expenses, before tax, for the 2018-2019 tax year.",
                        input: {
                            type: "number",
                            placeholder: "0",
                            id: "self_empolyed_net_profit_18_19_2",
                            min: 10000,
                            next: 72
                        }
                    }} id={"self_employed_net_profit_18_19_2"} setValue={(e) => props.setValue({ ...props.store, self_employed_net_profit_18_19_2: e })} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default SelfEmployed2;