/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { slide as M } from 'react-burger-menu';
import agent from '../agent';

class Landing_Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            res: {
                listings: []
            }
        };
    }

    async componentDidMount() {
        try{
            let res = await agent.Auth.current();
            console.log(res);
            this.setState({
                res: res,
                loggedIn: true
            });
        } catch (err) {
            console.log(err);
        }
    }

    redirectToHomepage(tab, tab2){
        localStorage.setItem("tab", JSON.stringify({"tab": tab, "tab2": tab2}));
        window.location.href = "/";
    }

    logout = () => {
		localStorage.clear();
		window.location.href = "/";
	}

    render() {
        return (
            <M customBurgerIcon={<i id="burger-icon" className="fas fa-bars"></i>} right pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" }>
                <div style={{color: "#AAFF00", letterSpacing: "2px"}}>FREE.CO.UK</div>

                <hr/>

                <a href="/buy">Mortgages</a>
                <a href="/about">About us</a>

                <hr/>

                <div style={{color: "white", marginBottom: "0px", display: this.state.loggedIn ? "none" : "block"}}>Already have an account?</div>
                <a href="/login" style={{textDecoration: "underline", display: this.state.loggedIn ? "none" : "block"}}>Log in here</a>
                <a onClick={this.logout} style={{display: !this.state.loggedIn ? "none" : "block"}}>Logout</a>

                <a href="/terms-of-use" style={{fontSize: "12px", color: "#999"}}>Terms of use</a>
                <a href="/privacy-policy" style={{fontSize: "12px", color: "#999"}}>Privacy policy</a>
                <a href="/service-agreement" style={{fontSize: "12px", color: "#999"}}>Service agreement</a>
            </M>
        );
    }
}

export default Landing_Menu;