import React, { useEffect, useState } from 'react';
import Selection from '../selection';

function ApplicationDetails(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case 0: 
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "We can help you find a mortgage",
                        content: "What would you like to do next?",
                        buttons: {
                            "Arrange a call": {
                                value: 1,
                                link: "https://calendly.com/hem-free/speak-to-a-free-advisor"
                            },
                            "Complete your application": {
                                value: 2,
                                next: 1
                            },
                            "Save your details": {
                                value: 2,
                                next: 1
                            },
                        }
                    }} id={"what_next"} setValue={() => {}} />
                )

            case 1:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "Who will be on the application?",
                        content: "Buying a home with a combined income can get you access to better offers",
                        buttons: {
                            "Just me": {
                                value: 1,
                                next: 2
                            },
                            "Me & someone else": {
                                value: 2,
                                next: 2
                            },
                            "More than two of us": {
                                value: 3,
                                modal: {
                                    id: "more_than_2_applicants",
                                    type: "callback",
                                    text: "Mortgages for more than two people require further information – it’s not a problem, we’ll just need a few more details."
                                }
                            },
                        }
                    }} id={"number_of_applicants"} setValue={(e) => props.setValue({ ...props.store, number_of_applicants: e })} />
                )
            case 2:
                return (
                    <span>
                        <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                            heading: "What's your name?",
                            content: "We need to understand a few details about you.",
                            input: {
                                type: "text",
                                id: "first_name",
                                placeholder: "First name",
                                next: 3,
                                min: 1,
                                button: false
                            }
                        }} id={"first_name"} setValue={(e) => props.setValue({ ...props.store, first_name: e })} />
                        <div style={{ marginTop: "-15px" }} />
                        <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                            input: {
                                type: "text",
                                id: "last_name",
                                placeholder: "Last name",
                                next: 3,
                                min: 1,
                                focus: false
                            }
                        }} id={"last_name"} setValue={(e) => props.setValue({ ...props.store, last_name: e })} />
                    </span>
                )

            case 3:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "When's your date of birth?",
                        content: "We won't send you a birthday card but this can impact how long your mortgage could be for. e.g. 25 years",
                        content2: "(Okay, we might send you a card).",
                        input: {
                            type: "date",
                            id: "dob",
                            next: 4,
                            min: 10
                        }
                    }} id={"date_of_birth"} setValue={(e) => props.setValue({ ...props.store, date_of_birth: e })} />
                )

            case 4:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "Are all of the applicants UK nationals?",
                        content: "There’s a slightly different process invloved for non-UK citizens when applying for a mortgage.",
                        buttons: {
                            "Yes": {
                                value: "YES",
                                next: 6
                            },
                            "No": {
                                value: "NO",
                                next: 5
                            }
                        }
                    }} id={"uk_nationals"} setValue={(e) => props.setValue({ ...props.store, uk_nationals: e })} />
                )

            case 5:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "What’s your current residency status?",
                        content: "Mortgage providers may require some further information, depending on your residency.",
                        buttons: {
                            "Indefinite leave to remain": {
                                value: "INDEFINITE",
                                next: 6
                            },
                            "More than two years on visa *": {
                                value: "MORE_THAN_TWO_YEARS",
                                modal: {
                                    id: "more_than_2_applicants",
                                    type: "callback",
                                    text: "Your visa status can affect what you can borrow – lenders will often use this as an indicator to refuse certain mortgage products."
                                }
                            },
                            "Less than two years on visa *": {
                                value: "LESS_THAN_TWO_YEARS",
                                modal: {
                                    id: "more_than_2_applicants",
                                    type: "callback",
                                    text: "Your visa status can affect what you can borrow – lenders will often use this as an indicator to refuse certain mortgage products."
                                }
                            }
                        },
                        content3: "* The current remaining time on your visa"
                    }} id={"residency_status"} setValue={(e) => props.setValue({ ...props.store, residency_status: e })} />
                )

            case 6:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "How many dependents do you have?",
                        content: "This includes any children or financially dependent adults over the age of 17 (please don't include any other applicants!)",
                        buttons: {
                            "0": 0,
                            "1": 1,
                            "2": 2,
                            "3": 3,
                            accordion: {
                                id: "dependants_accordion",
                                phrase: "More options",
                                alt: "Less options",
                                buttons: {
                                    "4": 4,
                                    "5": 5,
                                    "6": 6,
                                    "7": 7,
                                    "8": 8,
                                    "9": 9
                                }
                            }
                        },
                        next: 7
                    }} id={"dependants"} setValue={(e) => props.setValue({ ...props.store, dependants: e })} />
                )

            default:
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default ApplicationDetails;