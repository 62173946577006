import React, { useEffect, useState } from 'react';
import Selection from '../selection';

function Deposit(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case 104:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "How much do you have for the deposit?",
                        content: "A larger deposit can get you access to better deals (and for your budget of £500,000, the minimum will be £50,000).",
                        input: {
                            type: "number",
                            id: "deposit",
                            placeholder: "10000",
                            next: 105,
                            min: 5000
                        }
                    }} id={"deposit"} setValue={(e) => props.setValue({ ...props.store, deposit: e })} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default Deposit;