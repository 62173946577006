import React, { useEffect, useState } from 'react'
import FetchLenders from '../FetchLenders'
import const_lenders from "../lenders.json";
import links from '../lenderLinks'
import logos from '../logos'
import "./minimal.sass"
import Accordion from '../../Accordion/Accordion';
import AccordionButton from '../../Accordion/AccordionButton';
import reasons from '../prototype/lenderReasons';

function Minimal() {
    const [lenders, setLenders] = useState([])
    const [issue, setIssue] = useState("")
    const [time, setTime] = useState("")
    const [archive, setArchive] = useState([]);

    useEffect(() => {
        let input = []
        if (time !== "" && issue !== "") {
            input = [{ [issue]: time }]
        }
        console.log(input)
        setLenders(FetchLenders(input))
    }, [issue, time])

    useEffect(() => {
        let all = Object.keys(const_lenders);
        let arr = [];
        for (let i = 0; i < all.length; i++) {
            if (!lenders.includes(all[i])) {
                arr.push(all[i]);
            }
        }
        setArchive(arr);
    }, [lenders])

    function renderSwitch(input) {
        switch (input) {
            case "Payday Loans":
                return (
                    <select value={time} className="time_select" onChange={(e) => setTime(e.currentTarget.value)}>
                        <option value="" disabled selected>Select your option</option>
                        <option value="last 6 months">Last 6 months</option>
                        <option value="last 12 months">Last 12 months</option>
                        <option value="last 24 months">Last 24 months</option>
                    </select>
                )
            case "Bankruptcy": case "IVA": case "DMP":
                return (
                    <select value={time} className="time_select" onChange={(e) => setTime(e.currentTarget.value)}>
                        <option value="" disabled selected>Select your option</option>
                        <option value="last 3 years">Last 3 years</option>
                        <option value="last 6 years">Last 6 years</option>
                        <option value="> 6 years">{">"} 6 years</option>
                        <option value="ongoing">Ongoing</option>
                    </select>
                )
            case "CCJ": case "Missed Secured Payment": case "Defaults": case "Missed Unsecured Payment":
                return (
                    <select value={time} className="time_select" onChange={(e) => setTime(e.currentTarget.value)}>
                        <option value="" disabled selected>Select your option</option>
                        <option value="last 6 months">Last 6 months</option>
                        <option value="last 12 months">Last 12 months</option>
                        <option value="last 24 months">Last 24 months</option>
                        <option value="last 36 months">Last 36 months</option>
                        <option value="> 36 months">{">"} 36 months</option>
                    </select>
                )
            default:
                return (
                    <div className="placeholder">Select above value</div>
                )
        }
    }

    return (
        <div className="minimal">
            <div className="heading">Can I get a mortgage?</div>
            <div className="content">What describes your current situation?</div>

            <div className="selector_phrase">
                <div className="intro">"I have</div>
                <select className="time_select" onChange={(e) => {
                    setIssue(e.currentTarget.value)
                    setTime("")
                }}>
                    <option value="" disabled selected>Select your option</option>
                    <option value="Payday Loans">Had a payday loan</option>
                    <option value="Defaults">Defaulted on a loan</option>
                    <option value="Bankruptcy">Filed for bankruptcy</option>
                    <option value="Missed Secured Payment">Missed secured payment</option>
                    <option value="Missed Unsecured Payment">Missed unsecured payment</option>
                    <option value="CCJ">Had a CCJ</option>
                    <option value="IVA">Had an IVA</option>
                    <option value="DMP">Had a DMP</option>
                </select>

                <div style={{ height: "5px" }} />

                <div className="intro">in the<div className="ending">"</div></div>
                {renderSwitch(issue)}
            </div>

            <div className="content" style={{display: lenders.length === 0 ? "none" : ""}}>
                Good news!
                <br />
                {lenders.length} lender{lenders.length === 1 ? "s" : ""} can offer you a mortgage!
            </div>

            <div style={{display: lenders.length > 0 ? "none" : ""}}>
                Sorry!
                <br />
                {lenders.length} lender{lenders.length === 1 ? "s" : ""} can offer you a mortgage
            </div>

            <div style={{ textAlign: "center", display: "inline-block" }}>
                {lenders.map(e =>
                    <div className="lender_info">
                        <div className="logo" onClick={() => window.open(links[e], "_blank")}>{logos[e]}</div>
                        <div className="text">Accepted criteria:
                        <br />{
                                reasons[e].join(", ")
                            }</div>
                    </div>
                )}
            </div>

            <div className="archive" style={{ display: archive.length === 0 ? "none" : "" }}>
                <Accordion id="archive_acc">
                    {archive.map(e =>
                        <div className="lender_info">
                            <div style={{filter: "grayscale(100%)"}} className="logo" onClick={() => window.open(links[e], "_blank")}>{logos[e]}</div>
                            <div className="text">Accepted criteria:
                                <br />{
                                    reasons[e].join(", ")
                                }
                            </div>
                        </div>
                    )}
                </Accordion>
                <AccordionButton id="archive_acc" phrase="See those who will not" alt="Hide those who will not" />
            </div>
        </div >
    )
}

export default Minimal