import React, { useEffect, useState } from 'react';
import Selection from '../../../selection';

function DMP2(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case 102:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "Has " + props.store.first_name_2 + " ever been in a Debt Management Plan (DMP)?",
                        info: {
                            id: "dmp_modal_2",
                            text: "What is a debt management plan?",
                            content: "A debt management plan is an informal agreement that is made between yourself and a creditor (someone who you owe money to) to pay off unsecured debts in installments over time.",
                            content2: "A debt management plan is not a legal agreement, so there’s nothing to stop you applying for a mortgage while you’re repaying it. That said, you must be able to meet the criteria laid out by a lender in order to be approved, and credit issues can make it a complex application."
                        },
                        buttons: {
                            "Yes": {
                                value: "YES",
                                next: 103
                            },
                            "No": {
                                value: "NO",
                                next: 104
                            }
                        }
                    }} id={"had_a_dmp_2"} setValue={(e) => props.setValue({ ...props.store, had_a_dmp_2: e })} />
                )
            
            case 103:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "When was " + props.store.first_name_2 + " discharged?",
                        input: {
                            type: "month",
                            id: "dmp_discharged_2",
                            min: 7,
                            next: 104
                        },
                        sub_button: {
                            "Or mark this DMP as not yet satisfied": {
                                value: "ONGOING",
                                next: 104
                            }
                        }
                    }} id={"dmp_discharged_2"} setValue={(e) => props.setValue({ ...props.store, dmp_discharged_2: e })} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default DMP2;