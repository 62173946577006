import React, { useEffect, useState } from 'react';
import Selection from '../selection';

function CreditProfile(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case 22:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "Do you have any additional income?",
                        content: "This includes things like a car allowance or a second job.",
                        input: {
                            type: "number",
                            placeholder: "0",
                            id: "additional_income",
                            next: 23
                        }
                    }} id={"additional_income"} setValue={(e) => props.setValue({ ...props.store, additional_income: e })} />
                )

            case 23:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "What's your current credit card balance?",
                        content: "How much credit card debt will you have when your new mortgage starts?",
                        input: {
                            type: "number",
                            placeholder: "0",
                            id: "credit_card_balance",
                            next: 24
                        }
                    }} id={"credit_card_balance"} setValue={(e) => props.setValue({ ...props.store, credit_card_balance: e })} />
                )

            case 24:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "Do you have any adverse credit?",
                        content: "This refers to any negative payment information that would appear on your credit rating (such as defaulted loans, bankruptcy, missed payments and CCJs, or mortgage repossession).",
                        buttons: {
                            "Yes": {
                                value: "YES",
                                next: 25
                            },
                            "No": {
                                value: "NO",
                                next: props.store.number_of_applicants === 2 ? 54 : 104
                            }
                        }
                    }} id={"adverse_credit"} setValue={(e) => props.setValue({ ...props.store, adverse_credit: e })} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default CreditProfile;