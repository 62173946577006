import React, { useEffect, useState } from 'react';
import Selection from '../../selection';

function CreditProfile2(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case 72:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "Does " + props.store.first_name_2 + " have any additional income?",
                        content: "This includes things like a car allowance or a second job.",
                        input: {
                            type: "number",
                            placeholder: "0",
                            id: "additional_income_2",
                            next: 73
                        }
                    }} id={"additional_income_2"} setValue={(e) => props.setValue({ ...props.store, additional_income_2: e })} />
                )

            case 73:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "What's " + props.store.first_name_2 + "'s current credit card balance?",
                        content: "How much credit card debt will you have when your new mortgage starts?",
                        input: {
                            type: "number",
                            placeholder: "0",
                            id: "credit_card_balance_2",
                            next: 74
                        }
                    }} id={"credit_card_balance_2"} setValue={(e) => props.setValue({ ...props.store, credit_card_balance_2: e })} />
                )

            case 74:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "Does " + props.store.first_name_2 + " have any adverse credit?",
                        content: "This refers to any negative payment information that would appear on your credit rating (such as defaulted loans, bankruptcy, missed payments and CCJs, or mortgage repossession).",
                        buttons: {
                            "Yes": {
                                value: "YES",
                                next: 75
                            },
                            "No": {
                                value: "NO",
                                next: 104
                            }
                        }
                    }} id={"adverse_credit_2"} setValue={(e) => props.setValue({ ...props.store, adverse_credit_2: e })} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default CreditProfile2;