import React from 'react';

class Thanks extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            display: 0
        };  
    }

    componentDidMount() {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
        head.appendChild(script);
        document.body.style.overflow = "hidden";

        document.getElementsByClassName("bm-burger-button")[0].style.display = "none";
    }

    displayCalender = () => {
        let display = 1;
        if(this.state.display === 1){
            display = 0;
        }
        this.setState({
            display: display
        });
    }

    render() {
        let display = "none";
        let frontDisplay = "block";
        if(this.state.display === 1){
            display = "block";
            frontDisplay = "none";
        }

        let vh = window.innerHeight - 10;

        return (
            <div className="listing-flow" style={{textAlign: "center", width: "1500px", maxWidth:"100%", padding: "30px", overflow: "hidden", height: "calc(100vh)", maxHeight: "100%"}}>

                <div style={{display: frontDisplay, padding: "30px", width: "100%", transform: "translate(-50%, -50%)", position: "absolute", top: "50%", left: "50%" }}>

                    <div className="pageHeading" style={{textAlign: "center",paddingLeft: "30px", paddingRight: "30px"}}>Thanks for keeping in touch with our progress.</div>

                    <div className="pageContent" style={{textAlign: "center",marginBottom: "0px", paddingLeft: "30px", paddingRight: "30px", fontSize: "42px", lineHeight: "46px", textTransform: "uppercase" }}>Want to catch up on the phone?</div>

                    <button onClick={this.displayCalender}>Schedule a call with Ray</button>
                </div>

                {/* <!--<a href="" className="uk-button uk-button-large uk-width-1-1 cta-button">Setup a phone call</a>--> */}

                {/* <!-- Calendly link widget begin --> */}
                <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet" />
                <div style={{ display: display, minWidth: '320px', width: "1000px", height: '1500px', overflow: "hidden", maxHeight: vh, maxWidth: "95%", transform: "translate(-50%, -50%)", position: "absolute", top: "50%", left: "50%" }}>
                    <div onClick={this.displayCalender} style={{display: display, marginRight: "-90%", fontSize: "40px"}}>
                        <i class="fa fa-times" aria-hidden="true"></i>
                    </div>
                    <div
                        className="calendly-inline-widget"
                        data-url="https://calendly.com/free40/ray?back=1"
                        style={{height: "1000px", maxHeight: "95%", overflow: "hidden"}}
                        />
                </div>
                {/* <a href="" className="uk-button uk-button-large uk-width-1-1 cta-button" onClick={Calendly.initPopupWidget({url: 'https://calendly.com/free40?background_color=f8f8f8&text_color=64696f&primary_color=a400ff'})}>Schedule a call with Ray</a> */}
                {/* <!-- Calendly link widget end --> */}
            </div>

        );
    }
}

export default Thanks;