import React from 'react';
import AdverseCredit from './AdverseCredit';
import "./_creditladder.sass"

function CreditladderPrototype(props) {
    return (
        <div>
            <AdverseCredit combine/>
        </div>
    );
}

export default CreditladderPrototype;