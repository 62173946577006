import React, { Component } from 'react';

class Selections extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false
        }
    }

    render() {
        return (
            <button
                className={!this.state.active ? "inactive" : "active"}
                onClick={() => {
                    if (!this.state.active) {
                        this.props.addOption(this.props.value);
                    } else {
                        this.props.removeOption(this.props.value)
                    }
                    this.setState({ active: !this.state.active });
                }}
            >
                {this.props.children}
            </button>
        );
    }
}

export default Selections;