import React, { useEffect, useState } from 'react'
import AccountCreation from './sections/account_creation'
import Bankruptcy from './sections/adverse_credit/bankruptcy'
import CCJ from './sections/adverse_credit/ccj'
import Default from './sections/adverse_credit/default'
import DMP from './sections/adverse_credit/dmp'
import IVA from './sections/adverse_credit/iva'
import PaydayLoan from './sections/adverse_credit/payday_loan'
import SecuredPayment from './sections/adverse_credit/secure_payment'
import UnsecuredPayment from './sections/adverse_credit/unsecured_payment'
import ApplicationDetails from './sections/application_details'
import CreditProfile from './sections/credit_profile'
import Deposit from './sections/deposit'
import CompanyDirector from './sections/employment/company_director'
import Contractor from './sections/employment/contractor'
import Employed from './sections/employment/employed'
import Employment from './sections/employment/employment'
import SelfEmployed from './sections/employment/self_employed'
import Bankruptcy2 from './sections/second_applicant/adverse_credit/bankruptcy'
import CCJ2 from './sections/second_applicant/adverse_credit/ccj'
import Default2 from './sections/second_applicant/adverse_credit/default'
import DMP2 from './sections/second_applicant/adverse_credit/dmp'
import IVA2 from './sections/second_applicant/adverse_credit/iva'
import PaydayLoan2 from './sections/second_applicant/adverse_credit/payday_loan'
import SecuredPayment2 from './sections/second_applicant/adverse_credit/secure_payment'
import UnsecuredPayment2 from './sections/second_applicant/adverse_credit/unsecured_payment'
import CreditProfile2 from './sections/second_applicant/credit_profile'
import CompanyDirector2 from './sections/second_applicant/employment/company_director'
import Contractor2 from './sections/second_applicant/employment/contractor'
import Employed2 from './sections/second_applicant/employment/employed'
import Employment2 from './sections/second_applicant/employment/employment'
import SelfEmployed2 from './sections/second_applicant/employment/self_employed'
import SecondApplicant from './sections/second_applicant/second_applicant'
import "./full.sass"
import "./creditladder.sass"
import "./portify.sass"
import "./nrla.sass"

function Full(props) {
    const [store, setStore] = useState({first_name: "Rory", last_name: "Dobson"})
    const [page, setPage] = useState(105)
    const [prev, setPrev] = useState([105])
    const [num, setNum] = useState(1)
    // const [progress, setProgress] = useState(1)
    const [full, setFull] = useState(7)

    useEffect(() => {
        if (store.number_of_applicants === 1) {
            setFull(4)
        } else {
            setFull(7)
        }
        console.log(store)
    }, [store])

    useEffect(() => {
        if (prev.length < num && prev.length >= 1) {
            setPage(prev[prev.length - 1])
        }
        setNum(prev.length)
    }, [prev])

    useEffect(() => {
        // if (store.number_of_applicants === 1) {
        //     if (page >= 7) setProgress(2)
        //     else if (page >= 22) setProgress(3)
        //     else if (page >= 104) setProgress(4)
        //     else if (page >= 106) setProgress(5)
        // } else {
        //     if (page >= 7) setProgress(2)
        //     else if (page >= 22) setProgress(3)
        //     else if (page >= 54) setProgress(4)
        //     else if (page >= 57) setProgress(5)
        //     else if (page >= 72) setProgress(6)
        //     else if (page >= 104) setProgress(7)
        //     else if (page >= 106) setProgress(8)
        // }

    }, [page])

    return (
        <div className={props.match.params.style ? props.match.params.style : "encompass"}>
            <div className="adverse_flow">
                <div className="navbar">
                    <button style={{ display: prev.length <= 1 ? "none" : "" }} className="back" onClick={() => setPrev(prev.slice(0, -1))}>
                        <img className="backArrow" src={require("../../img/icon-arrow-back.svg")} alt="back"></img>
                        <div className="backText">Back</div>
                    </button>
                    {/* <img className="logo" src={require("../../img/logo.svg")} alt="free" ></img> */}
                    <br /><br /><br />
                    <div className="progress_bar" style={{ display: page > 1 ? "" : "none" }}>
                        <div className={page >= 7 ? "progress active" : "progress"} style={{ width: full === 4 ? "calc((100% / 4) - 4px)" : "calc((100% / 7) - 4px)"}}></div>
                        <div className={page >= 22 ? "progress active" : "progress"} style={{ width: full === 4 ? "calc((100% / 4) - 4px)" : "calc((100% / 7) - 4px)"}}></div>
                        <div className={page >= 54 ? "progress active" : "progress"} style={{ display: full === 7 ? "" : "none", width: "calc((100% / 7) - 4px)"}}></div>
                        <div className={page >= 57 ? "progress active" : "progress"} style={{ display: full === 7 ? "" : "none", width: "calc((100% / 7) - 4px)"}}></div>
                        <div className={page >= 72 ? "progress active" : "progress"} style={{ display: full === 7 ? "" : "none", width: "calc((100% / 7) - 4px)"}}></div>
                        <div className={page >= 104 ? "progress active" : "progress"} style={{ width: full === 4 ? "calc((100% / 4) - 4px)" : "calc((100% / 7) - 4px)"}}></div>
                        <div className={page >= 106 ? "progress active" : "progress"} style={{ width: full === 4 ? "calc((100% / 4) - 4px)" : "calc((100% / 7) - 4px)"}}></div>
                    </div>
                </div>

                {/* Pages 1 - 6 */}
                <ApplicationDetails prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Page 7 */}
                <Employment prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 8 - 10 */}
                <Employed prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 11 - 14 */}
                <SelfEmployed prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 15 - 20 */}
                <CompanyDirector prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Page 21 */}
                <Contractor prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 22 - 24 */}
                <CreditProfile prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 25 - 29 */}
                <CCJ prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 30 - 35 */}
                <Default prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 36 - 39 */}
                <SecuredPayment prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 40 - 43 */}
                <UnsecuredPayment prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 44 - 47 */}
                <PaydayLoan prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 48 - 49 */}
                <Bankruptcy prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 50 - 51 */}
                <IVA prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 52 - 53 */}
                <DMP prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 54 - 56 */}
                <SecondApplicant prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Page 57 */}
                <Employment2 prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 58 - 60 */}
                <Employed2 prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 61 - 64 */}
                <SelfEmployed2 prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 65 - 70 */}
                <CompanyDirector2 prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Page 71 */}
                <Contractor2 prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 72 - 74 */}
                <CreditProfile2 prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 75 - 79 */}
                <CCJ2 prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 80 - 85 */}
                <Default2 prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 86 - 89 */}
                <SecuredPayment2 prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 90 - 93 */}
                <UnsecuredPayment2 prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 94 - 97 */}
                <PaydayLoan2 prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 98 - 99 */}
                <Bankruptcy2 prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 100 - 101 */}
                <IVA2 prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 102 - 103 */}
                <DMP2 prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Page 104 */}
                <Deposit prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />

                {/* Pages 105 - 106 */}
                <AccountCreation prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={setStore} />
            </div>
        </div>
    );
}

export default Full;