import React, { useEffect, useState } from 'react';
import * as Icon from "react-bootstrap-icons"
import PhoneInput from './phone_input';
import axios from "axios"

function AuthModal(props) {
    const [phoneNumber, setPhoneNumber] = useState("7399266184")
    const [countryCode, setCountryCode] = useState("+44")
    const [id, setId] = useState("")
    const [page, setPage] = useState(1)
    const [code, setCode] = useState("")
    const [token, setToken] = useState("")

    function request_code() {
        axios.post(process.env.REACT_APP_API_URL + "/verify/send_code", {
            phone_number: countryCode.slice(1) + phoneNumber
        }).then(res => {
            setId(res.data.id)
            if (res.data.success) {
                setPage(2)
            }
        })
    }

    function verify_code() {
        axios.post(process.env.REACT_APP_API_URL + "/verify/verify_code", {
            id: id,
            token: code
        }).then(res => {
            signup()
        })
    }

    function signup() {
        axios.post(process.env.REACT_APP_API_URL + "/auth/signup", {
            name: props.store.first_name + " " + props.store.last_name,
            phone_number: countryCode + "(0)" + phoneNumber
        }).then(res => {
            signin()
        }).catch(err => {
            signin()
        })
    }

    function signin() {
        axios.post(process.env.REACT_APP_API_URL + "/auth/signin", {
            phone_number: countryCode + "(0)" + phoneNumber
        }).then(res => {
            if (res.data.success) {
                setToken(res.data.data.accessToken)
            }
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        console.log(token)
        saveData()
    }, [token])

    function saveData() {
        axios.post(process.env.REACT_APP_API_URL + "/mortgage/save", {
            phone_number: countryCode + "(0)" + phoneNumber,
            data: props.store
        }, { headers: { "x-access-token": token } }).then(res => {
            if (res.data.success) {
                console.log(res)
                setPage(3)
                sendNotification(res.data.id)
            } else if(res.data.requires_update) {
                console.log(res)
                updateData(res.data.id)
            }
        })
    }

    function updateData(id) {
        axios.post(process.env.REACT_APP_API_URL + "/mortgage/update", {
            id: id,
            data: props.store
        }, { headers: { "x-access-token": token } }).then(res => {
            if (res.data.success) {
                setPage(3)
                console.log(res)
                sendNotification(id)
            }
        })
    }

    function sendNotification(id) {
        axios.post(process.env.REACT_APP_API_URL + "/notify", {
            type: "mortgage_flow",
            details: {
                name: props.store.first_name + " " + props.store.last_name,
                number: countryCode + "(0)" + phoneNumber,
                id: id
            }
        })
    }

    return (
        <div className="modal" id="auth_modal" style={{ display: props.show ? "block" : "none" }}>
            {page === 1 ?
                <div className="modalContent">
                    {/* <div className="modalX" onClick={() => document.getElementById("auth_modal").style.display = "none"}><Icon.X /></div> */}
                    <div className="heading">
                        Your mobile number
                    </div>
                    <div className="content">
                        We'll send you a 6-digit security code to verify your account.
                    </div>
                    <PhoneInput cc={e => setCountryCode(e)} number={e => setPhoneNumber(e)} countryCode={countryCode} phoneNumber={phoneNumber} />
                    <div style={{ textAlign: "left", marginLeft: "15px" }}>
                        <button onClick={() => request_code()} style={{ padding: "10px 40px" }} className="capBtn">
                            Next
                        </button>
                    </div>
                </div>
                :
                page === 2 ?
                    <div className="modalContent">
                        {/* <div className="modalX" onClick={() => document.getElementById("auth_modal").style.display = "none"}><Icon.X /></div> */}
                        <div className="heading">
                            Please enter your 6-digit security code
                    </div>
                        <div className="content">
                            Sent via SMS to {countryCode} (0){phoneNumber}.
                    </div>
                        <div onClick={() => request_code()} className="content" style={{ textDecoration: "underline", marginTop: "-15px", cursor: "pointer" }}>
                            Resend
                    </div>
                        <input value={code} onChange={e => setCode(e.currentTarget.value)} type="number" placeholder="Enter your code" style={{ width: "calc(100% - 51px)" }} />
                        <div style={{ textAlign: "left", marginLeft: "15px" }}>
                            <button disabled={code.length === 6 ? "" : "disabled"} onClick={() => verify_code()} style={{ padding: "10px 40px" }} className="capBtn">
                                Next
                        </button>
                        </div>
                        <div className="content">
                            Wrong number?
                    </div>
                        <div onClick={() => setPage(1)} className="content" style={{ textDecoration: "underline", marginTop: "-15px", cursor: "pointer" }}>
                            Go back
                    </div>
                    </div>
                    :
                    page === 3 ?
                        <div className="modalContent">
                            {/* <div className="modalX" onClick={() => document.getElementById("auth_modal").style.display = "none"}><Icon.X /></div> */}
                            <div className="heading">
                                Successfully created an account
                            </div>
                            <div className="content">
                                Next time you come here you can sign in and see your results.
                            </div>
                            <div style={{ textAlign: "left", marginLeft: "15px" }}>
                                <button className="white" onClick={() => document.getElementById("auth_modal").style.display = "none"}>
                                    Got it
                                </button>
                            </div>
                        </div>
                        : null}
        </div>
    );
}

export default AuthModal;