import React, { useEffect, useState } from 'react';
import Selection from '../../selection';

function DMP(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case 52:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "Have you ever been in a Debt Management Plan (DMP)?",
                        info: {
                            id: "dmp_modal",
                            text: "What is a debt management plan?",
                            content: "A debt management plan is an informal agreement that is made between yourself and a creditor (someone who you owe money to) to pay off unsecured debts in installments over time.",
                            content2: "A debt management plan is not a legal agreement, so there’s nothing to stop you applying for a mortgage while you’re repaying it. That said, you must be able to meet the criteria laid out by a lender in order to be approved, and credit issues can make it a complex application."
                        },
                        buttons: {
                            "Yes": {
                                value: "YES",
                                next: 53
                            },
                            "No": {
                                value: "NO",
                                next: props.store.number_of_applicants === 1 ? 104 : 54
                            }
                        }
                    }} id={"had_a_dmp"} setValue={(e) => props.setValue({ ...props.store, had_a_dmp: e, ccjs: [] })} />
                )
            
            case 53:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "When were you discharged?",
                        input: {
                            type: "month",
                            id: "dmp_discharged",
                            min: 7,
                            next: props.store.number_of_applicants === 1 ? 104 : 54
                        },
                        sub_button: {
                            "Or mark this DMP as not yet satisfied": {
                                value: "ONGOING",
                                next: props.store.number_of_applicants === 1 ? 104 : 54
                            }
                        }
                    }} id={"dmp_discharged"} setValue={(e) => props.setValue({ ...props.store, dmp_discharged: e })} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default DMP;