import React, { useEffect, useState } from 'react';
import Selection from '../../../selection';

function SecuredPayment2(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        if (props.page === 89 && props.store.missed_secured_payment_date_2 !== undefined && props.store.missed_secured_payment_amount_2 !== undefined) {
            let missed_secured_payment_date_2 = props.store.missed_secured_payment_date_2
            let missed_secured_payment_amount_2 = props.store.missed_secured_payment_amount_2
            delete props.store.missed_secured_payment_date_2
            delete props.store.missed_secured_payment_amount_2
            props.setValue({ ...props.store, missed_secured_payments_2: [...props.store.missed_secured_payments_2, {missed_secured_payment_date_2: missed_secured_payment_date_2, missed_secured_payment_amount_2: missed_secured_payment_amount_2 }]})
        }
        setDisplay(displayPage())
    }, [props.page, props.store])

    function remove_missed_secured_payment(i) {
        props.store.missed_secured_payments_2.splice(i, 1)
        props.setValue({...props.store, missed_secured_payments_2: props.store.missed_secured_payments_2})
    }

    function displayPage() {
        switch (props.page) {
            case 86:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "Has " + props.store.first_name_2 + " ever missed a secured payment?",
                        info: {
                            id: "secured_payment_loan_modal_3",
                            text: "What is a secured payment?",
                            content: "A secured late payment relates to a mortgage or a secured loan that has been secured against assets, such as your home or car. If you fail to make payments, a creditor can seize the assets that the debt has been secured against.",
                        },
                        buttons: {
                            "Yes": {
                                value: "YES",
                                next: 87
                            },
                            "No": {
                                value: "NO",
                                next: 90
                            }
                        }
                    }} id={"had_a_missed_secured_payment_2"} setValue={(e) => props.setValue({ ...props.store, had_a_missed_secured_payment_2: e, missed_secured_payments_2: [] })} />
                )

            case 87:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "When did " + props.store.first_name_2 + " last miss a secured payment?",
                        input: {
                            type: "month",
                            id: "missed_secured_payment_date_2",
                            min: 7,
                            next: 88
                        }
                    }} id={"missed_secured_payment_date_2"} setValue={(e) => props.setValue({ ...props.store, missed_secured_payment_date_2: e })} />
                )

            case 88:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "How much was the secured payment for?",
                        input: {
                            type: "number",
                            placeholder: "10000",
                            id: "missed_secured_payment_amount_2",
                            next: 89
                        }
                    }} id={"missed_secured_payment_amount_2"} setValue={(e) => props.setValue({ ...props.store, missed_secured_payment_amount_2: e })} />
                )

            case 89:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "Please list all of " + props.store.first_name_2 + "'s missed secured payments since May 2019",
                        info: {
                            id: "secured_payment_loan_modal_4",
                            text: "What is a secured payment?",
                            content: "A secured late payment relates to a mortgage or a secured loan that has been secured against assets, such as your home or car. If you fail to make payments, a creditor can seize the assets that the debt has been secured against.",
                        },
                        collection: {
                            type: "missed_secured_payment",
                            append: "_2",
                            add: 87
                        },
                        next: 90
                    }} id={"missed_secured_payment_collection_2"} setValue={(e) => {}} remove={(e) => remove_missed_secured_payment(e)} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default SecuredPayment2;