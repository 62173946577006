import React, { useEffect, useState } from 'react';
import Selection from '../../../selection';

function Employment2(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case 57:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "What is " + props.store.first_name_2 +" employment status?",
                        content: "This will have an impact on the other financial details we’ll need.",
                        buttons: {
                            "Employed": {
                                value: "EMPLOYED",
                                next: 58
                            },
                            "Self employed": {
                                value: "SELF_EMPLOYED",
                                next: 61
                            },
                            "Company director": {
                                value: "COMPANY_DIRECTOR",
                                next: 65
                            },
                            "Contractor": {
                                value: "CONTRACTOR",
                                next: 71
                            },
                            accordion: {
                                id: "employment",
                                phrase: "See more options",
                                alt: "See less options",
                                buttons: {
                                    "Retired": {
                                        value: "RETIRED",
                                        next: 72
                                    },
                                    "Homemaker": {
                                        value: "HOMEMAKER",
                                        next: 72
                                    },
                                    "Student": {
                                        value: "STUDENT",
                                        next: 72
                                    },
                                    "Unemployed": {
                                        value: "UNEMPLOYED",
                                        next: 72
                                    }
                                }
                            }
                        },
                    }} id={"employment_2"} setValue={(e) => props.setValue({ ...props.store, employment_2: e })} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default Employment2;