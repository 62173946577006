import additionalRules from "./additionalRules";
import lenders from "./lenders.json";

function FetchLenders(input) {
    let arr = [];
    let list = Object.keys(lenders);
    let res = false
    let info = {}
    let lenderInfo = {}
    for (let i = 0; i < list.length; i++) {
        [res, lenderInfo] = compareLender(list[i], input)
        info[list[i]] = lenderInfo
        if (res) {
            arr.push(list[i]);
        };
    }
    return [arr, info];
}

function compareLender(lender, input) {
    let leading = ["Accord", "Bank of Ireland", "Clydesdale Bank", "Coventry", "Digital", "HSBC", "Leeds BS", "Metro Bank", "Nationwide", "NatWest", "Nottingham BS", "Platform", "Santander", "Skipton", "TSB", "Virgin Money", "Dudley BS"]
    let lenderInfo = require("./lenders.json")[lender];
    let suggested = true;
    if(lender === "Darlington BS") {
        lenderInfo.LTV = {
            "70%": true,
            "75%": true,
            "80%": true,
            "85%": false,
            "90%": false,
            "95%": false
        }
    }
    if(lender === "Ipswich BS") {
        lenderInfo.LTV = {
            "70%": true,
            "75%": true,
            "80%": true,
            "85%": true,
            "90%": true,
            "95%": false
        }
    }
    if(lender === "Hinckley and Rugby") {
        lenderInfo.LTV = {
            "70%": true,
            "75%": true,
            "80%": true,
            "85%": true,
            "90%": true,
            "95%": true
        }
    }
    if(lender === "Leek Utd") {
        lenderInfo.LTV = {
            "70%": true,
            "75%": true,
            "80%": true,
            "85%": true,
            "90%": false,
            "95%": false
        }
    }
    for (let i = 0; i < input.length; i++) {
        let comp = Object.entries(input[i])[0];
        if (comp[0] !== "LTV" && leading.includes(lender)) {
            lenderInfo.LTV["85%"] = false
            lenderInfo.LTV["90%"] = false
            lenderInfo.LTV["95%"] = false
        }
        lenderInfo = additionalRules(lender, lenderInfo, comp[0], comp[1])
        if (!lenderInfo[comp[0]][comp[1]]) {
            suggested = false;
        };
    }

    return [suggested, lenderInfo];
}

export default FetchLenders;