import React from "react";
import Slider from 'infinite-react-carousel';
import "./about.sass";
import "./landing_3.sass";

class About extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            num: 3
        };
    }

    render() {
        let s = document.body.clientWidth;
        console.log(s);
        let n = 4;
        if (s < 840 && s >= 640){
            n = 3;
        } else if (s < 640 && s >= 440){
            n = 2;
        } else if(s < 440){
            n = 1;
        }

        let settings =  {
            arrows: false,
            arrowsBlock: false,
            autoplay: true,
            autoplaySpeed: 2000,
            duration: 200,
            pauseOnHover: false,
            swipe: false,
            onResize: () => {
                this.render();
                this.forceUpdate();
            },
            overScan: 4
        };

        return (
            <div className="about">
                <div className="header">
                    <img className="logo" src={require("./img/logo.svg")} alt="Logo"></img>
                    <hr/>
                </div>

                <div className="welcome">
                    <div className="text">
                        <div className="heading">
                            Welcome to Free.co.uk
                        </div>

                        <div className="subHeading">
                            Have you ever wondered why everyone hates estate agents?
                        </div>

                        <div className="subHeading" style={{marginBottom: "24px"}}>
                            We have.
                        </div>

                        <div className="content">
                            We’re an estate agent that charges no fees or commission. We advertise your home on websites like Rightmove and we give you free professional photos and a floorplan. You control your sale and stay in control; we support you when you need it. 
                        </div>

                        <div className="content" style={{marginBottom: "36px"}}>
                            We are turning the industry on its head by making our money from finding mortgages for our buyers rather than taking fees or commission. 
                        </div>
                    </div>
                    
                    <div className="image">
                        <img className="rayImg" src={require("./img/ray-portrait.svg")} alt="Ray"></img>
                    </div>

                    <hr/>
                </div>

                <div className="columns">
                    <div className="columnL">
                        <img src={require("./img/illustration-lightbulb.svg")} alt="Lightblub"></img>
                    </div>

                    <div className="column2">
                        <div className="subHeading">
                            We had a lightbulb moment and asked: Why can’t the selling process be free? We could make money from all the mortgages people buy. 
                        </div>
                        <div className="subHeading">
                            So we built Free.co.uk
                        </div>
                        <div className="content">
                            Say goodbye to the online agent who charges you just to stick your property on Rightmove and do all the work yourself.  High street estate agents do a good job - we aren’t trying to replace them. But if you don’t want to pay commission, or you think you can do it better, we’re a new choice without any up-front fees or commissions.
                        </div>
                    </div>

                    <div className="columnAlt">
                        <img src={require("./img/illustration-lightbulb.svg")} alt="Lightblub"></img>
                    </div>
                </div>

                <div className="partners">
                    <div>Some of our partners</div>
                    <Slider key={n} slidesToShow={n} { ...settings }>
                        <div className="partner">
                            <div className="logo">
                                <img className="partnerLogo" src={require("./img/logo-rightmove.png")} alt="Rightmove"></img>
                            </div>
                            <div className="description">
                                Property advertising
                            </div>
                        </div>
                        <div className="partner">
                            <div className="logo">
                                <img className="partnerLogo" src={require("./img/logo-experian.png")} alt="Experian"></img>
                            </div>
                            <div className="description">
                                Credit files
                            </div>
                        </div>
                        <div className="partner">
                            <div className="logo">
                                <img className="partnerLogo" src={require("./img/logo-the-property-ombudsman.png")} alt="The company Ombudsman"></img>
                            </div>
                            <div className="description">
                                Company standards
                            </div>
                        </div>
                        <div className="partner">
                            <div className="logo">
                                <img className="partnerLogo" src={require("./img/logo-splento.png")} alt="Splento"></img>
                            </div>
                            <div className="description">
                                Professional photos
                            </div>
                        </div>
                    </Slider>
                </div>

                <div className="columns">
                    <div className="column1">
                        Is Free.co.uk for you?
                    </div>
                    <div className="column2">
                        <div className="subHeading">
                            Free.co.uk is for people who want control - for people who know their home and are perfectly capable of showing to buyers.
                        </div>
                        <div className="content">
                            Our tech and website make listing a home as easy as ordering a take-away or posting on social media. You dictate the price, the description, and when viewings happen. You accept or decline offers. 
                        </div>
                    </div>
                    <hr/>
                </div>

                <div className="columns">
                    <div className="column1">
                        How does it work?
                    </div>
                    <div className="column2">
                        <div className="subHeading">
                            You list your home in 3 minutes. We give you professional photos and a floorplan. Once they are back your home is on Rightmove. For free. 
                        </div>
                        <div className="content">
                            <ul>
                                <li>You control and carry out all of the viewings.</li>
                                <li>You receive, accept and decline all offers.</li>
                                <li>
                                    You handle negotiations.
                                    <br/><br/>
                                    You can tell us if you need help or get stuck – you won't be left high and dry (promise).
                                </li>
                            </ul>
                        </div>
                    </div>
                    <hr/>
                </div>

                <div className="columns">
                    <div className="column1">
                        How can we be free?
                    </div>
                    <div className="column2">
                        <div className="subHeading">
                            Our ‘Skyscanner for mortgages’ helps buyers find a mortgage and that’s how we get paid — on average £750 per deal. The beauty is that our sellers can see if a buyer has their finance in place — this reduces the amount of deals falling through. 
                        </div>
                        <div className="content">
                            If you also have a home to sell, there's no risk to trying Free.co.uk out — you'll have your home professionally listed within 5 minutes. 
                            <br/><br/>
                            To keep our service simple, we don't have a call centre — if you do get stuck, please do email <a href="mailto:born@free.co.uk">born@free.co.uk</a>
                            <br/><br/>
                            Best of luck,
                        </div>
                        <img className="signature" src={require("./img/signature-ray-black.svg")} alt="Rays signature"></img>
                        <div className="content">
                            Ray and the team at Free.co.uk
                        </div>
                    </div>
                </div>

                <div className="footer">
                    <div className="content">
                        Sell your home without paying fees or commission
                    </div>
                    
                    <a href="https://www.linkedin.com/company/freecouk/" >
						<img className="socialIcon" src={require("./img/social-linkedin.svg")} alt='linkedIn'></img>
					</a>

					<a href="https://www.youtube.com/channel/UCTHBn84d5gx92jRB6xKyL6A" >
						<img className="socialIcon" src={require("./img/social-youtube.svg")} alt='youtube'></img>
					</a>

                    <div className="contentSmall">
						Free free free ltd is a registered company No.12429135. Copyright &copy; 2020-2021 Free free free
						ltd. All rights reserved.
					</div>
                </div>
            </div>
        );
    }
}

export default About;