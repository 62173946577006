const environments = {
  production: {
    env: "production",
    authApi: "https://freeco-auth-microservice.herokuapp.com/api",
    api: "https://freeco-api.herokuapp.com/api",
    scannerApi: "https://mortgage-scanner.herokuapp.com",
    zapier: "https://hooks.zapier.com",
  },
  staging: {
    env: "staging",
    authApi: "https://freeco-auth-microservice.herokuapp.com/api",
    api: "https://freeco-api-staging.herokuapp.com/api",
    scannerApi: "https://mortgage-scanner.herokuapp.com",
    zapier: "https://hooks.zapier.com", // TODO: staging events
  },
  development: {
    env: "development",
    authApi: "https://freeco-auth-microservice.herokuapp.com/api",
    api: "http://localhost:3001/api",
    scannerApi: "https://mortgage-scanner.herokuapp.com",
    // scannerApi: "http://localhost:8000",
    zapier: "https://hooks.zapier.com", // TODO: dev events
  },
};

const env = process.env.REACT_APP_DEPLOY_ENV || "development";

console.info(`Loading [${env}] config`);

export const config = environments[env];
