import axios from "axios";
import { config } from "./config";
const { authApi, scannerApi, api, zapier } = config;

//------AUTH TOKEN-------//
let token = localStorage.getItem("token");
function setToken(_token) {
  localStorage.setItem("token", _token);
  token = _token;
}

//------UTILITY AXIOS-------//
const axiosRequestsNoAuth = (ROOT_API) => ({
  get: (url) => axios.get(`${ROOT_API}${url}`).then((res) => res.data),
  post: (url, body) =>
    axios.post(`${ROOT_API}${url}`, body).then((res) => res.data),
});
const axiosRequestsToken = (ROOT_API) => ({
  get: (url, opts = {}) =>
    axios
      .get(`${ROOT_API}${url}`, { headers: { Authorization: token }, ...opts })
      .then((res) => res.data),
  post: (url, body) =>
    axios
      .post(`${ROOT_API}${url}`, body, { headers: { Authorization: token } })
      .then((res) => res.data),
  put: (url, body) =>
    axios
      .put(`${ROOT_API}${url}`, body, { headers: { Authorization: token } })
      .then((res) => res.data),
  del: (url) =>
    axios
      .delete(`${ROOT_API}${url}`, { headers: { Authorization: token } })
      .then((res) => res.data),
  patch: (url, body) =>
    axios
      .patch(`${ROOT_API}${url}`, body, { headers: { Authorization: token } })
      .then((res) => res.data),
});

//------DIFFERENT SERVER AXIOS--------//
const AuthRequests = axiosRequestsNoAuth(authApi);
const AuthRequestsToken = axiosRequestsToken(authApi);
const ZapierRequests = axiosRequestsNoAuth(zapier);
const MainRequestsToken = axiosRequestsToken(api);
const MainRequestsNoToken = axiosRequestsNoAuth(api);

//------EXPORTED FUNCTION TO UTILIZE CALLS--------//
const Auth = {
  login: (phone, title, name, surname) => {
    console.log(authApi);
    return AuthRequests.post("/smsauth/login", {
      user: { phone, title, name, surname },
    });
  },
  verifyCode: (verifyCode, smsCode, message = "") =>
    AuthRequests.post("/smsauth/verifycode", {
      verifyCode,
      smsCode,
      message,
    }).then((res) => {
      setToken(res.token);
      return res;
    }),
  logout: () => {
    token = null;
    localStorage.clear();
  },
  current: () => AuthRequestsToken.get("/user"),
  loginMagicLink: (code) =>
    AuthRequests.post("/magiclink/login", { code }).then((res) => {
      setToken(res.token);
      return res;
    }),
};

const MortgageScanner = {
  query: (mipQuery, getHistory) =>
    axios
      .post(`${scannerApi}/query${getHistory ? "?history=true" : ""}`, mipQuery)
      .then((r) => r.data),
};

const omitSlug = (listing) => ({ ...listing, slug: undefined });
const Listings = {
  userListings: () => MainRequestsToken.get(`/listings`),
  del: (slug) => MainRequestsToken.del(`/listings/${slug}`), // Delete single listing
  get: (slug) => MainRequestsToken.get(`/listings/${slug}`), // Return single listing
  getPublic: (slug) => MainRequestsToken.get(`/public/listings/${slug}`),
  update: (slug, listing) =>
    MainRequestsToken.put(`/listings/${slug}`, { listing: omitSlug(listing) }), // Update single listing
  create: (listing) => MainRequestsToken.post("/listings", { listing }), // Create single listing,
  publish: (slug) => MainRequestsToken.put(`/listings/${slug}/publish`),
  unpublish: (slug) => MainRequestsToken.put(`/listings/${slug}/unpublish`),
  sendImage: (slug, formData) => {
    return axios
      .post(`${api}/listings/${slug}/photos`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
        onUploadProgress: (progressEvent) =>
          console.log(
            `Upload progress ${Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            )}%`
          ),
      })
      .then((res) => res.data);
  },
  removeImage: (slug, imageId) =>
    MainRequestsToken.del(`/listings/${slug}/photos/${imageId}`),
  checkImageEnhanceReady: (slug, imageId) =>
    MainRequestsToken.get(`/listings/${slug}/photos/${imageId}/enhanced`),
  toggleEnhancedImage: (slug, imageId) =>
    MainRequestsToken.put(`/listings/${slug}/photos/${imageId}/switch`),
  bookPhotosession: (slug, date) =>
    MainRequestsToken.post(`/listings/${slug}/photosession`, { date }),
};

const Offers = {
  BUYER: {
    GetOffers: () => MainRequestsToken.get("/offers"),
    MakeOffer: (slug, offer) =>
      MainRequestsToken.post(`/listings/${slug}/offers`, { offer }),
    CancelOffer: (offerId, reasons) =>
      MainRequestsToken.put(`/offers/decline/${offerId}`, { reasons }),
  },
  SELLER: {
    GetListingOffers: (slug) =>
      MainRequestsToken.get(`/listings/${slug}/offers`),
    AcceptOffer: (slug, offerId, conditions) =>
      MainRequestsToken.put(`/listings/${slug}/offers/${offerId}/accept`, {
        conditions,
      }),
    DeclineOffer: (slug, offerId, reasons) =>
      MainRequestsToken.put(`/listings/${slug}/offers/${offerId}/decline`, {
        reasons,
      }),
  },
};

const Viewings = {
  BUYER: {
    getAll: () => MainRequestsToken.get(`/viewings`),
    createViewing: (listingSlug, viewing) =>
      MainRequestsToken.post(`/viewings/create/${listingSlug}`, { viewing }),
    declineViewing: (viewingId) =>
      MainRequestsToken.put(`/viewings/decline/${viewingId}`),
    sendFeedback: (viewingId, feedback) =>
      MainRequestsToken.put(`/viewings/feedback/${viewingId}`, { feedback }),
  },
  SELLER: {
    getListingViewings: (listingSlug) =>
      MainRequestsToken.get(`/listings/${listingSlug}/viewings`),
    confirmViewing: (listingSlug, viewingId) =>
      MainRequestsToken.put(
        `/listings/${listingSlug}/viewings/confirm/${viewingId}`
      ),
    declineViewing: (listingSlug, viewingId) =>
      MainRequestsToken.put(
        `/listings/${listingSlug}/viewings/cancel/${viewingId}`
      ),
  },
};

const Mortgage = {
  create: (details) => MainRequestsToken.post("/mortgage/user/create", details),
  getResults: (getHistory = false) =>
    MainRequestsToken.get(
      `/mortgage/results${getHistory ? "?history=true" : ""}`
    ),
  queryResults: (query, getHistory = false) =>
    MainRequestsToken.post(
      `/mortgage/results${getHistory ? "?history=true" : ""}`,
      query
    ),
  getPdf: () =>
    MainRequestsToken.get(`/mortgage/mip.pdf`, { responseType: "blob" }),
  getExampleResults: (getHistory = false) =>
    MainRequestsNoToken.get(
      `/mortgage/results/example${getHistory ? "?history=true" : ""}`
    ),
  updateMip: (mipId, details) =>
    MainRequestsToken.post(`/mortgage/mip/${mipId}`, details),
  requestCallbackHelp: (title, name, surname, phone, reason) => {
    const obj = { title, name, surname, phone, reason };
    return ZapierRequests.post("/hooks/catch/7821874/owx1i3b", {
      action: "Mortgage Help",
      message: `${name} ${surname}; ${phone}, requests a callback for a mortgage. (${reason})`,
    });
  },
  getFactFindState() {
    return MainRequestsToken.get(`/fullFactFind`);
  },
  updateFactFindState(ffState) {
    return MainRequestsToken.post(`/fullFactFind/save`, ffState);
  },
};

const Admin = {
  approveFeedback: (slug, viewingId, feedback) =>
    MainRequestsToken.put(
      `/admin/listings/${slug}/viewings/${viewingId}/feedback/approve`,
      { feedback }
    ),
  getViewing: (listingSlug, viewingId) =>
    MainRequestsToken.get(
      `/admin/listings/${listingSlug}/viewings/${viewingId}`
    ),
};

const Zapier = {
  requestCallbackHelp: (action, message) => {
    return ZapierRequests.post("/hooks/catch/7821874/owx1i3b/", {
      action,
      message,
    });
  },
};

export default {
  Listings,
  Auth,
  Viewings,
  Mortgage,
  Offers,
  authApi,
  Admin,
  Zapier,
  MortgageScanner,
  hasToken: !!token,
  setToken,
  getToken: () => token,
};
