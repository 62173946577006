import React from "react";

import cambridge_bs from "./img/cambridge_bs.png";
import buckinghamshire_bs from "./img/buckinghamshire_bs.png";
import mansfield_bs from "./img/mansfield_bs.png";
import darlington_bs from "./img/darlington_bs.png";
import saffron_bs from "./img/saffron_bs.png";
import together from "./img/together.png";
import the_mortgage_lender from "./img/the_mortgage_lender.png";
import precise from "./img/precise.jpg";
import pepper_money from "./img/pepper_money.png";
import foundation from "./img/foundation.png";
import kent_reliance from "./img/kent_reliance.png";
import aldermore from "./img/aldermore.png";
import kensington from "./img/kensington.jpg";
import bluestone from "./img/bluestone.jpg";
import vida_homeloans from "./img/vida_homeloans.png";
import halifax from "./img/halifax.png";
import scottish_widows from "./img/scottish_widows.png"
import accord from "./img/accord.jpg"
import barclays from "./img/barclays.png"
import nationwide from "./img/nationwide.jpg"
import natwest from "./img/natwest.png"
import coventry from "./img/coventry.png"
import santander from "./img/santander.png"
import skipton from "./img/skipton.png"
import leeds_bs from "./img/leeds_bs.png"
import hsbc from "./img/hsbc.png"
import platform from "./img/platform.jpg"
import virgin from "./img/virgin.png"
import tsb from "./img/tsb.png"
import clydesdale_bank from "./img/clydesdale_bank.jpg"
import metro_bank from "./img/metro_bank.jpg"
import dudley_bs from "./img/dudley_bs.jpg"
import bank_of_ireland from "./img/bank_of_ireland.jpg"
import west_brom from "./img/west_brom.png"
import nottingham_bs from "./img/nottingham_bs.jfif"
import principality from "./img/principality.jpg"
import newbury from "./img/newbury.jpg"
import newcastle_bs from "./img/newcastle_bs.png"
import marsden_bs from "./img/marsden_bs.png"
import market_harborough from "./img/market_harborough.png"
import ipswich_bs from "./img/ipswich_bs.jpg"
import hinckley_and_rugby from "./img/hinckley_and_rugby.png"
import harpenden_bs from "./img/harpenden_bs.jfif"
import furness_bs from "./img/furness_bs.jpg"
import bath_bs from "./img/bath_bs.jpg"
import beverley from "./img/beverley.jpg"
import chorley from "./img/chorley.jpg"
import digital from "./img/digital.png"
import family_building_society from "./img/family_building_society.jpg"
import hanley_economic from "./img/hanley_economic.jpg"
import leek_utd from "./img/leek_utd.png"
import loughborough from "./img/loughborough.png"
import mbs_lending from "./img/mbs_lending.jpg"
import masthaven from "./img/masthaven.png"
import monmouthshire from "./img/monmouthshire.png"
import scottish_bs from "./img/scottish_bs.jfif"
import stafford_railway from "./img/stafford_railway.png"
import teachers_bs from "./img/teachers_bs.jpg"
import tipton from "./img/tipton.jpg"
import vernon_bs from "./img/vernon_bs.png"

// import blank from "./img/blank.jpg"

const logos = {
    "Cambridge BS": <img className="lender_logo" src={cambridge_bs}></img>,
    "Buckinghamshire BS": <img className="lender_logo" src={buckinghamshire_bs}></img>,
    "Mansfield BS": <img className="lender_logo" src={mansfield_bs}></img>,
    "Darlington BS": <img className="lender_logo" src={darlington_bs}></img>,
    "Saffron BS": <img className="lender_logo" src={saffron_bs}></img>,
    "Together": <img className="lender_logo" src={together}></img>,
    "The Mortgage Lender": <img className="lender_logo" src={the_mortgage_lender}></img>,
    "Precise": <img className="lender_logo" src={precise}></img>,
    "Pepper Money": <img className="lender_logo" src={pepper_money}></img>,
    "Foundation": <img className="lender_logo" src={foundation}></img>,
    "Kent Reliance": <img className="lender_logo" src={kent_reliance}></img>,
    "Aldermore": <img className="lender_logo" src={aldermore}></img>,
    "Kensington": <img className="lender_logo" src={kensington}></img>,
    "Bluestone": <img className="lender_logo" src={bluestone}></img>,
    "Vida Homeloan": <img className="lender_logo" src={vida_homeloans}></img>,
    "Halifax": <img className="lender_logo" src={halifax}></img>,
    "Scottish Widows": <img className="lender_logo" src={scottish_widows}></img>,
    "Accord": <img className="lender_logo" src={accord}></img>,
    "Barclays": <img className="lender_logo" src={barclays}></img>,
    "Nationwide": <img className="lender_logo" src={nationwide}></img>,
    "NatWest": <img className="lender_logo" src={natwest}></img>,
    "Coventry": <img className="lender_logo" src={coventry}></img>,
    "Santander": <img className="lender_logo" src={santander}></img>,
    "Skipton": <img className="lender_logo" src={skipton}></img>,
    "Leeds BS": <img className="lender_logo" src={leeds_bs}></img>,
    "HSBC": <img className="lender_logo" src={hsbc}></img>,
    "Platform": <img className="lender_logo" src={platform}></img>,
    "Virgin Money": <img className="lender_logo" src={virgin}></img>,
    "TSB": <img className="lender_logo" src={tsb}></img>,
    "Clydesdale Bank": <img className="lender_logo" src={clydesdale_bank}></img>,
    "Metro Bank": <img className="lender_logo" src={metro_bank}></img>,
    "Dudley BS": <img className="lender_logo" src={dudley_bs}></img>,
    "Bank of Ireland": <img className="lender_logo" src={bank_of_ireland}></img>,
    "West Brom": <img className="lender_logo" src={west_brom}></img>,
    "Nottingham BS": <img className="lender_logo" src={nottingham_bs}></img>,
    "Principality": <img className="lender_logo" src={principality}></img>,
    "Newbury": <img className="lender_logo" src={newbury}></img>,
    "Newcastle BS": <img className="lender_logo" src={newcastle_bs}></img>,
    "Marsden BS": <img className="lender_logo" src={marsden_bs}></img>,
    "Market Harborough": <img className="lender_logo" src={market_harborough}></img>,
    "Ipswich BS": <img className="lender_logo" src={ipswich_bs}></img>,
    "Hinckley and Rugby": <img className="lender_logo" src={hinckley_and_rugby}></img>,
    "Harpenden BS": <img className="lender_logo" src={harpenden_bs}></img>,
    "Furness BS": <img className="lender_logo" src={furness_bs}></img>,
    "Bath BS": <img className="lender_logo" src={bath_bs}></img>,

    "Beverley": <img className="lender_logo" src={beverley}></img>,
    "MBS Lending": <img className="lender_logo" src={mbs_lending}></img>,
    "Family Building Society": <img className="lender_logo" src={family_building_society}></img>,
    "Vernon BS": <img className="lender_logo" src={vernon_bs}></img>,
    "Loughborough": <img className="lender_logo" src={loughborough}></img>,
    "Monmouthshire": <img className="lender_logo" src={monmouthshire}></img>,
    "Chorley": <img className="lender_logo" src={chorley}></img>,
    "Hanley Economic": <img className="lender_logo" src={hanley_economic}></img>,
    "Leek Utd": <img className="lender_logo" src={leek_utd}></img>,
    "Stafford Railway": <img className="lender_logo" src={stafford_railway}></img>,
    "Scottish BS": <img className="lender_logo" src={scottish_bs}></img>,
    "Teachers BS": <img className="lender_logo" src={teachers_bs}></img>,
    "Tipton": <img className="lender_logo" src={tipton}></img>,
    "Masthaven": <img className="lender_logo" src={masthaven}></img>,
    "Digital": <img className="lender_logo" src={digital}></img>
}

export default logos;