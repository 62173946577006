import React, { useEffect, useState } from 'react';
import Selection from '../../../selection';

function Employed2(props) {
    const [display, setDisplay] = useState(null)

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case 58:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "Employment status",
                        content: "Lenders need to know this so they can work out how much they will lend you.",
                        buttons: {
                            "Permanent contract": {
                                value: "PERMANENT",
                                next: 59
                            },
                            "Fixed term contract": {
                                value: "FIXED_TERM",
                                next: 59
                            },
                            "Subcontractor (fixed term)": {
                                value: "SUBCONTRACTOR_FIXED_TERM",
                                next: 59
                            },
                            "Subcontractor (open ended)": {
                                value: "SUBCONTRACTOR_OPEN_ENDED",
                                next: 59
                            },
                            "Temporary contract": {
                                value: "TEMPORARY",
                                next: 59
                            },
                        },
                    }} id={"employment_contract_2"} setValue={(e) => props.setValue({ ...props.store, employment_contract_2: e })} />
                )

            case 59:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "What's " + props.store.first_name_2 + "'s annual income?",
                        content: "This is before tax, and excluding any  bonuses, commission, or overtime.",
                        input: {
                            type: "number",
                            placeholder: "10000",
                            id: "annual_income_2",
                            min: 10000,
                            next: 60
                        }
                    }} id={"annual_income_2"} setValue={(e) => props.setValue({ ...props.store, annual_income_2: e })} />
                )

            case 60:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "Are there any monthly deductions included on " + props.store.first_name_2 + "'s payslip?",
                        content: "This includes monthly childcare vouchers, student loan repayments, season ticket loan repayments, or other deductions after tax.",
                        input: {
                            type: "number",
                            placeholder: "10000",
                            id: "deductions_2",
                            next: 72
                        }
                    }} id={"deductions_2"} setValue={(e) => props.setValue({ ...props.store, deductions_2: e })} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default Employed2;