import React, { useEffect, useState } from 'react';
import Selection from '../../selection';

function CCJ(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        if (props.page === 29 && props.store.ccj_date !== undefined && props.store.ccj_amount !== undefined && props.store.ccj_settled !== undefined) {
            let ccj_date = props.store.ccj_date
            let ccj_amount = props.store.ccj_amount
            let ccj_settled = props.store.ccj_settled
            delete props.store.ccj_date
            delete props.store.ccj_amount
            delete props.store.ccj_settled
            props.setValue({ ...props.store, ccjs: [...props.store.ccjs, {ccj_date: ccj_date, ccj_amount: ccj_amount, ccj_settled: ccj_settled }]})
        }
        setDisplay(displayPage())
    }, [props.page, props.store])

    function remove_ccj(i) {
        props.store.ccjs.splice(i, 1)
        props.setValue({...props.store, ccjs: props.store.ccjs})
    }

    function displayPage() {
        switch (props.page) {
            case 25:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "Have you ever had a CCJ?",
                        info: {
                            id: "ccj_modal",
                            text: "What is a CCJ?",
                            content: "A county court judgment (CCJ) can negatively affect your ability to get credit for up to six years. That means loans, credit cards, and even mobile phone contracts may be out of your reach.",
                            content2: "A creditor may apply for a CCJ against you if they think you won't repay money you owe them. If the courts agree with the creditor, they'll issue the judgment and tell you to pay the money back. You'll receive information about your CCJ in the post."
                        },
                        buttons: {
                            "Yes": {
                                value: "YES",
                                next: 26
                            },
                            "No": {
                                value: "NO",
                                next: 30
                            }
                        }
                    }} id={"had_a_ccj"} setValue={(e) => props.setValue({ ...props.store, had_a_ccj: e, ccjs: [] })} />
                )

            case 26:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "When was your most recent CCJ issued?",
                        input: {
                            type: "month",
                            id: "ccj_date",
                            min: 7,
                            next: 27
                        }
                    }} id={"ccj_date"} setValue={(e) => props.setValue({ ...props.store, ccj_date: e })} />
                )

            case 27:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "How much was the CCJ for?",
                        input: {
                            type: "number",
                            placeholder: "10000",
                            id: "ccj_amount",
                            next: 28
                        }
                    }} id={"ccj_amount"} setValue={(e) => props.setValue({ ...props.store, ccj_amount: e })} />
                )

            case 28:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "When was the CCJ settled?",
                        input: {
                            type: "month",
                            id: "ccj_settled",
                            min: 7,
                            next: 29
                        },
                        sub_button: {
                            "Or mark this CCJ as not yet satisfied": {
                                value: "ONGOING",
                                next: 29
                            }
                        }
                    }} id={"ccj_settled"} setValue={(e) => props.setValue({ ...props.store, ccj_settled: e })} />
                )

            case 29:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "Please list all CCJs issued since May 2019",
                        info: {
                            id: "ccj_modal_2",
                            text: "What is a CCJ?",
                            content: "A county court judgment (CCJ) can negatively affect your ability to get credit for up to six years. That means loans, credit cards, and even mobile phone contracts may be out of your reach.",
                            content2: "A creditor may apply for a CCJ against you if they think you won't repay money you owe them. If the courts agree with the creditor, they'll issue the judgment and tell you to pay the money back. You'll receive information about your CCJ in the post."
                        },
                        collection: {
                            type: "ccj",
                            add: 26
                        },
                        next: 30
                    }} id={"ccj_collection"} setValue={(e) => {}} remove={(e) => remove_ccj(e)} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default CCJ;