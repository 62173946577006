import React, { Component } from 'react';
import "./accordion.sass";

class Accordion extends Component {
    render() {
        return (
            <div className="accordion" style={{maxHeight: "0px"}} id={this.props.id}>
                {this.props.children}
            </div>
        );
    }
}

export default Accordion;