import React, { useEffect, useState } from 'react';
import Accordion from '../../Accordion/Accordion';
import AccordionButton from '../../Accordion/AccordionButton';
import * as Icon from "react-bootstrap-icons"
require("datejs");

function Selection(props) {
    const [input, setInput] = useState(props.store[props.id] !== null && props.store[props.id] !== undefined ? props.store[props.id] : "")
    const [day, setDay] = useState(props.store[props.id] && props.page?.input?.type === "date" ? props.store[props.id].split("-")[2] : "")
    const [month, setMonth] = useState(props.store[props.id] && props.page?.input?.type === "date" ? props.store[props.id].split("-")[1] : "")
    const [year, setYear] = useState(props.store[props.id] && props.page?.input?.type === "date" ? props.store[props.id].split("-")[0] : "")

    useEffect(() => {
        if (input !== undefined) props.setValue(input)
    }, [input])

    useEffect(() => {
        let id = document.getElementById(props.page.input?.id + "_input")
        if (id) {
            if (props.page.input?.focus !== false) {
                id.focus()
            }
        }
        setDay(props.store[props.id] && props.page?.input?.type === "date" ? props.store[props.id].split("-")[2] : "")
        setMonth(props.store[props.id] && props.page?.input?.type === "date" ? props.store[props.id].split("-")[1] : "")
        setYear(props.store[props.id] && props.page?.input?.type === "date" ? props.store[props.id].split("-")[0] : "")
    }, [props.id])

    useEffect(() => {
        setInput(props.store[props.id] !== null && props.store[props.id] !== undefined ? props.store[props.id] : "")
    }, [props.id])

    useEffect(() => {
        console.log(props.store[props.id] && props.page?.input?.type === "date" ? props.store[props.id].split("-") : null)
        if (props.page?.input?.type === "date") setInput(year + "-" + month + "-" + day)
    }, [day, month, year, props.id])

    return (
        <div className="adverse_flow">

            <div className="applicantReference" style={{ display: props.page.reference ? "" : "none" }}>
                {props.page.reference}
            </div>
            <div className="heading">
                {props.page.heading}
            </div>
            <div className="content">
                {props.page.content}
            </div>
            <div className="content" style={{ display: !props.page.content2 ? "none" : "", marginTop: "-10px" }}>
                {props.page.content2}
            </div>
            <div className="content info" style={{ display: !props.page.info ? "none" : "" }} onClick={() => document.getElementById(props.page.info.id).style.display = "block"}>
                {props.page?.info?.text}
            </div>

            {props.page?.info ?
                <div className="modal" id={props.page.info.id} style={{ display: "none" }}>
                    <div className="modalContent">
                        <div className="modalX" onClick={() => document.getElementById(props.page.info.id).style.display = "none"}><Icon.X /></div>
                        <div className="heading">
                            {props.page?.info?.text}
                        </div>
                        <div className="content">
                            {props.page.info.content}
                        </div>
                        <div className="content" style={{ display: !props.page.info.content2 ? "none" : "" }}>
                            {props.page.info.content2}
                        </div>
                        <div className="content">
                            <button className="white" onClick={() => document.getElementById(props.page.info.id).style.display = "none"}>
                                Got it
                            </button>
                        </div>
                    </div>
                </div>
                : null}

            {props.page.buttons ? Object.entries(props.page.buttons).map(e => {
                if (e[0] !== "accordion") {
                    return (
                        <div style={{ textAlign: "left" }}>
                            <button className={e[1]?.small ? "radio small" : "radio"} style={{
                                paddingLeft: e[1]?.dot === false ? "21px" : "",
                            }} key={e[0]} onClick={() => {
                                props.setValue(e[1].value || e[1])
                                if (e[1]?.next || props.page.next) {
                                    props.setPage(e[1]?.next || props.page.next)
                                    props.setPrev([...props.prev, e[1]?.next || props.page.next])
                                } else if (e[1]?.modal) {
                                    document.getElementById(e[1].modal.id).style.display = "block"
                                } else if (e[1]?.link) {
                                    window.location.href = e[1].link
                                }
                            }}>
                                <div className={props.store[props.id] !== null && props.store[props.id] !== undefined && (props.store[props.id] === e[1]?.value || props.store[props.id] === e[1]) ? "radio_circle active" : "radio_circle"} 
                                style={{
                                    display: e[1]?.dot === false ? "none" : ""
                                }}>
                                    <img
                                        style={{
                                            visibility: props.store[props.id] !== null && props.store[props.id] !== undefined && (props.store[props.id] === e[1]?.value || props.store[props.id] === e[1])
                                                ? "visible"
                                                : "hidden"
                                        }} className="tick" src={require("../../img/tick-white.png")} alt="tick"></img>
                                </div>
                                {e[0]}
                            </button>
                            {e[1]?.modal && e[1]?.modal.type === "callback" ?
                                <div className="modal" id={e[1].modal.id} style={{ display: "none" }}>
                                    <div className="modalContent">
                                        <div className="modalX" onClick={() => document.getElementById(e[1].modal.id).style.display = "none"}><Icon.X /></div>
                                        <div className="heading">
                                            Please book a call with one of our advisors
                                        </div>
                                        <div className="content">
                                            {e[1].modal.text}
                                        </div>
                                        <div className="content">
                                            <strong>We want to help.</strong>
                                        </div>
                                        <div className="content">
                                            Arrange a callback and one of our advisers to review different options over the phone, to ensure we get you accurate results.
                                        </div>
                                        <div className="content">
                                            <button className="black" onClick={() => window.location.href = "https://calendly.com/hem-free/speak-to-a-free-advisor"}>
                                                Arrange a callback
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>
                    )
                } else {
                    return (
                        <div key={e[0]}>
                            <Accordion id={props.page.buttons.accordion.id}>
                                {Object.entries(props.page.buttons.accordion.buttons).map(e => {
                                    return (
                                        <button className="radio" key={e[0]} onClick={() => {
                                            props.setValue(e[1].value || e[1])
                                            props.setPage(e[1]?.next || props.page.next)
                                            props.setPrev([...props.prev, e[1]?.next || props.page.next])
                                        }}>
                                            <div className="radio_circle" className={props.store[props.id] !== null && props.store[props.id] !== undefined && (props.store[props.id] === e[1]?.value || props.store[props.id] === e[1]) ? "radio_circle active" : "radio_circle"}>
                                                <img
                                                    style={{
                                                        visibility: props.store[props.id] !== null && props.store[props.id] !== undefined && (props.store[props.id] === e[1]?.value || props.store[props.id] === e[1])
                                                            ? "visible"
                                                            : "hidden"
                                                    }} className="tick" src={require("../../img/tick-white.png")} alt="tick"></img>
                                            </div>
                                            {e[0]}
                                        </button>
                                    )
                                })}
                            </Accordion>
                            <div className="radio flow accordion_radio">
                                <AccordionButton id={props.page.buttons.accordion.id} phrase={props.page.buttons.accordion.phrase} alt={props.page.buttons.accordion.alt} />
                            </div>
                        </div>
                    )
                }
            }) : null}

            {props.page.input ?
                <div className="input_contain">
                    <div className="input_wrap">
                        <div style={{ display: props.page.input.type === "number" ? "inline-block" : "none" }} className="pound_sign">£</div>
                        {props.page.input.type === "date"
                            ?
                            <div>
                                <select className="day" id={props.page.input.id + "_input"} value={day} onChange={(e) => {
                                    setDay(e.currentTarget.value)
                                }}>
                                    <option value="" disabled hidden>Day</option>
                                    <option value="01">1</option>
                                    <option value="02">2</option>
                                    <option value="03">3</option>
                                    <option value="04">4</option>
                                    <option value="05">5</option>
                                    <option value="06">6</option>
                                    <option value="07">7</option>
                                    <option value="08">8</option>
                                    <option value="09">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                    <option value="29">29</option>
                                    <option value="30" disabled={month === "02" ? "disabled" : ""}>30</option>
                                    <option value="31" disabled={month === "02" || month === "04" || month === "06" || month === "09" || month === "11" ? "disabled" : ""}>31</option>
                                </select>
                                <select className="month" value={month} onChange={(e) => {
                                    setMonth(e.currentTarget.value)
                                }}>
                                    <option value="" disabled selected hidden>Month</option>
                                    <option value="01">January</option>
                                    <option value="02" disabled={parseInt(day) > 29 ? "disabled" : ""}>February</option>
                                    <option value="03">March</option>
                                    <option value="04" disabled={parseInt(day) > 30 ? "disabled" : ""}>April</option>
                                    <option value="05">May</option>
                                    <option value="06" disabled={parseInt(day) > 30 ? "disabled" : ""}>June</option>
                                    <option value="07">July</option>
                                    <option value="08">August</option>
                                    <option value="09" disabled={parseInt(day) > 30 ? "disabled" : ""}>September</option>
                                    <option value="10">October</option>
                                    <option value="11" disabled={parseInt(day) > 30 ? "disabled" : ""}>November</option>
                                    <option value="12">December</option>
                                </select>
                                <select className="year" value={year} onChange={(e) => {
                                    setYear(e.currentTarget.value)
                                }}>
                                    <option value="" disabled selected hidden>Year</option>
                                    <option value="2003">2003</option>
                                    <option value="2002">2002</option>
                                    <option value="2001">2001</option>
                                    <option value="2000">2000</option>
                                    <option value="1999">1999</option>
                                    <option value="1998">1998</option>
                                    <option value="1997">1997</option>
                                    <option value="1996">1996</option>
                                    <option value="1995">1995</option>
                                    <option value="1994">1994</option>
                                    <option value="1993">1993</option>
                                    <option value="1992">1992</option>
                                    <option value="1991">1991</option>
                                    <option value="1990">1990</option>
                                    <option value="1989">1989</option>
                                    <option value="1988">1988</option>
                                    <option value="1987">1987</option>
                                    <option value="1986">1986</option>
                                    <option value="1985">1985</option>
                                    <option value="1984">1984</option>
                                    <option value="1983">1983</option>
                                    <option value="1982">1982</option>
                                    <option value="1981">1981</option>
                                    <option value="1980">1980</option>
                                    <option value="1979">1979</option>
                                    <option value="1978">1978</option>
                                    <option value="1977">1977</option>
                                    <option value="1976">1976</option>
                                    <option value="1975">1975</option>
                                    <option value="1974">1974</option>
                                    <option value="1973">1973</option>
                                    <option value="1972">1972</option>
                                    <option value="1971">1971</option>
                                    <option value="1970">1970</option>
                                    <option value="1969">1969</option>
                                    <option value="1968">1968</option>
                                    <option value="1967">1967</option>
                                    <option value="1966">1966</option>
                                    <option value="1965">1965</option>
                                    <option value="1964">1964</option>
                                    <option value="1963">1963</option>
                                    <option value="1962">1962</option>
                                    <option value="1961">1961</option>
                                    <option value="1960">1960</option>
                                    <option value="1959">1959</option>
                                    <option value="1958">1958</option>
                                    <option value="1957">1957</option>
                                    <option value="1956">1956</option>
                                    <option value="1955">1955</option>
                                    <option value="1954">1954</option>
                                    <option value="1953">1953</option>
                                    <option value="1952">1952</option>
                                    <option value="1951">1951</option>
                                    <option value="1950">1950</option>
                                    <option value="1949">1949</option>
                                    <option value="1948">1948</option>
                                    <option value="1947">1947</option>
                                    <option value="1946">1946</option>
                                    <option value="1945">1945</option>
                                    <option value="1944">1944</option>
                                    <option value="1943">1943</option>
                                    <option value="1942">1942</option>
                                    <option value="1941">1941</option>
                                    <option value="1940">1940</option>
                                    <option value="1939">1939</option>
                                    <option value="1938">1938</option>
                                    <option value="1937">1937</option>
                                    <option value="1936">1936</option>
                                    <option value="1935">1935</option>
                                    <option value="1934">1934</option>
                                    <option value="1933">1933</option>
                                    <option value="1932">1932</option>
                                    <option value="1931">1931</option>
                                    <option value="1930">1930</option>
                                    <option value="1929">1929</option>
                                    <option value="1928">1928</option>
                                    <option value="1927">1927</option>
                                    <option value="1926">1926</option>
                                    <option value="1925">1925</option>
                                    <option value="1924">1924</option>
                                    <option value="1923">1923</option>
                                    <option value="1922">1922</option>
                                    <option value="1921">1921</option>
                                    <option value="1920">1920</option>
                                </select>
                            </div>
                            :
                            <input id={props.page.input.id + "_input"} style={{
                                paddingLeft: props.page.input.type === "number" ? "30px" : "",
                                width: props.page.input.type === "number" ? "100%" : props.page.input.type === "years" ? "100%" : "",
                                paddingRight: props.page.input.type === "years" ? "75px" : "",
                                maxWidth: props.page.input.type === "number" ? "calc(100% - 42px)" : props.page.input.type === "years" ? "calc(100% - 88px)" : ""
                            }}
                                value={input}
                                type={props.page.input.type === "years" ? "number" : props.page.input.type} placeholder={props.page.input.placeholder} onChange={(e) => {
                                    setInput(props.page.input.type === "number" && !isNaN(parseInt(e.currentTarget.value)) ? parseInt(e.currentTarget.value) : e.currentTarget.value)
                                }}
                                max={props.page.input.type === "date" || props.page.input.type === "month" ? new Date().toISOString().split("T")[0] : null}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        document.getElementById(props.page.input.id + "_button").click()
                                    }
                                }}
                            ></input>
                        }
                        <div style={{ display: props.page.input.type === "years" ? "inline-block" : "none" }} className="following_label">years</div>
                    </div>
                    <button style={{ display: props.page.input?.button === false ? "none" : "" }} id={props.page.input.id + "_button"} className="capBtn" onClick={() => {
                        props.setPage(props.page.input.next)
                        props.setPrev([...props.prev, props.page.input.next])
                    }} disabled={props.page.input.type === "date" && input.length < props.page.input.min ? "disabled" :
                        props.page.input.type === "number" && input < props.page.input.min ? "disabled" :
                            props.page.input.type === "years" && input < props.page.input.min ? "disabled" :
                                props.page.input.type === "month" && input.length < props.page.input.min ? "disabled" :
                                    props.page.input.type === "text" && input.length < props.page.input.min ? "disabled" : ""}>
                        next
                    </button>
                </div>
                :
                null
            }

            {props.page.collection ?
                <div className="collection">
                    {props.store[props.page.collection.type + "s" + (props.page.collection.append ? props.page.collection.append : "")].map((e, i) => {
                        return (
                            <div key={i}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {i + 1}
                                            </td>
                                            <td>
                                                {Date.parse(e[props.page.collection.type + "_date" + (props.page.collection.append ? props.page.collection.append : "")] + "-01").toString("MMMM yyyy")}
                                            </td>
                                            <td style={{ textAlign: "right", height: "20px", paddingTop: "10px", fontSize: "15px" }}>
                                                £{e[props.page.collection.type + "_amount" + (props.page.collection.append ? props.page.collection.append : "")]}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>{e[props.page.collection.type + "_settled" + (props.page.collection.append ? props.page.collection.append : "")] === "ONGOING" ? "Ongoing" : e[props.page.collection.type + "_settled" + (props.page.collection.append ? props.page.collection.append : "")] ? "Settled " + Date.parse(e[props.page.collection.type + "_settled"] + "-01").toString("MMMM yyyy") : null}</td>
                                            <td>
                                                <span onClick={() => props.remove(i)}>Remove</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )
                    })}
                    <div className="add_another"
                        onClick={() => {
                            props.setPage(props.page.collection.add)
                            props.setPrev([...props.prev, props.page.collection.add])
                        }}>+ Add Another</div>
                    <br />
                    <button style={{ display: props.page.input?.button === false ? "none" : "", marginLeft: "15px" }} className="capBtn" onClick={() => {
                        props.setPage(props.page.next)
                        props.setPrev([...props.prev, props.page.next])
                    }} >
                        next
                    </button>
                </div>
                :
                null
            }

            <div className="content" style={{ display: !props.page.content3 ? "none" : "", marginTop: "20px" }}>
                {props.page.content3}
            </div>

            {props.page.sub_button ?
                Object.entries(props.page.sub_button).map(e =>
                    <div key={e[0]} style={{ textAlign: "left", margin: "0px 15px" }}>
                        <div onClick={() => {
                            props.setPage(e[1].next)
                            props.setPrev([...props.prev, e[1].next])
                            props.setValue(e[1].value)
                        }} className="sub_button">
                            {e[0]}
                        </div>
                    </div>
                )
                : null
            }
        </div >
    )
}

export default Selection;