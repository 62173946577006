const reasons = {
    "Cambridge BS" : {
        "LTV": "Minimum 15% deposit.",
        "CCJ": "Defaults or CCJ up to £500 outstanding or satisfied within the last 24 months (if outstanding must be repaid on completion). Defaults or CCJ up to £5,000 outstanding or satisfied within the last 36 months (if outstanding must be repaid on completion).",
        "Defaults": "Defaults or CCJ up to £500 outstanding or satisfied within the last 24 months (if outstanding must be repaid on completion). Defaults or CCJ up to £5,000 outstanding or satisfied within the last 36 months (if outstanding must be repaid on completion).",
        "Missed Secured Payment": "Up to 2 missed secured payments in the last 24 months.",
        "Missed Unsecured Payment": "Five or more missed payments on credit card, mail order, utility or communications in the last 24 months. Three or more missed loan or unsecured financial commitment payments in the last 24 months.",
        "Bankruptcy": "",
        "IVA": "",
        "DMP": "",
        "Payday Loans": "",
        "Repossession": "",
        "Other": ""
    },
    "Buckinghamshire BS" : {
        "LTV": "Minimum 30% deposit.",
        "CCJ": "No CCJs in the last 3 months.",
        "Defaults": "Defaults up to £1000 in the last 24 months. Must be satisfied. 0 in the last 6 months.",
        "Missed Secured Payment": "Max status of 3 missed secured payments in the last 24 months. 0 in the last 6 months.",
        "Missed Unsecured Payment": "Max status 2 missed unsecured payments in the last 24 months.",
        "Bankruptcy": "Bankruptcy must be discharged by the time of application.",
        "IVA": "IVAs must be discharged by the time of application.",
        "DMP": "DMP started in the last 3 years needs satisfactory conduct.",
        "Payday Loans": "",
        "Repossession": "",
        "Other": ""
    },
    "Mansfield BS" : {
        "LTV": "Minimum 15% deposit.",
        "CCJ": "CCJ with max £500 unsatisfied in last 36 months. If more than 36 months, can be ignored if satisfied or less than £1000 and unsatisfied.",
        "Defaults": "",
        "Missed Secured Payment": "0 missed secured payments in the last 6 months.",
        "Missed Unsecured Payment": "0 unsecured loan payments in the last 6 months. 0 other unsecured payments in the last 3 months. 0 unsecured credit agreements in the last 24 months unless comms/utilites/store card then 1 in the last 24 months (can't exceed £1000).",
        "Bankruptcy": "Bankruptcy has to be discharged for 3 years.",
        "IVA": "IVA has to be discharged for 3 years.",
        "DMP": "DMP needs satisfactory conduct for 24 months.",
        "Payday Loans": "No payday loans in the last 3 months.",
        "Repossession": "",
        "Other": ""
    },
    "Darlington BS" : {
        "LTV": "Minimum 20% deposit.",
        "CCJ": "1 or more CCJ's in the last 3 years greater than £500. 35% deposit minimum. Has to be satisfied in completion. Less than £500 and minimum deposit of 30%.",
        "Defaults": "Default greater than £500 with minimum deposit of 30%. Registered over 2 years ago with minimum deposit of 20%. Over 3 years and less than £500 is ignored. If acceptable story, can accept defaults in the last 6 months. Has to be satisfied on completion.",
        "Missed Secured Payment": "Up to 4 missed secured payments in the last two years with minimum deposit of 35%. Have to be caught up to date.",
        "Missed Unsecured Payment": "Up to 4 missed unsecured payments in the last two years with minimum deposit of 35%. Have to be caught up to date.",
        "Bankruptcy": "Minimum deposit of 35% if bankrupt in last 3 years. Minimum deposit of 30% if bankrupt in last 6 years. Bankruptcy must be discharged.",
        "IVA": "Minimum deposit of 35% with IVA in last 3 years. Minimum deposit of 30% with IVA in last 6 years. IVA must be discharged.",
        "DMP": "At least 20% deposit if DMP discharged in the last year. Ongoing DMP has to be in place for 3 years. Requires satisfactory conduct.",
        "Payday Loans": "Max 5 payday loans in the last 12 months. Have to be satisfied.",
        "Repossession": "If repossessed, minimum deposit of 30% required.",
        "Other": ""
    },
    "Saffron BS" : {
        "LTV": "Minimum 10% deposit.",
        "CCJ": "CCJ in last 12 months must be settled with a max of £250 total. CCJ in last 24 months must be settled with a max of £500 total.",
        "Defaults": "Default in last 12 months must be settled with a max of £250 total. Default in last 24 months must be settled with a max of £500 total.",
        "Missed Secured Payment": "Missed secured or unsecured payment must be settled. No more than 2 occurrences in the last 24 months.",
        "Missed Unsecured Payment": "Missed secured or unsecured payment must be settled. No more than 2 occurrences in the last 24 months.",
        "Bankruptcy": "Bankruptcy must be discharged for at least 5 years.",
        "IVA": "",
        "DMP": "",
        "Payday Loans": "",
        "Repossession": "",
        "Other": "No current adverse issues allowed."
    },
    "Together" : {
        "LTV": "Minimum 25% deposit.",
        "CCJ": "Up to CCJs 2 in the last 12 months (if the customer has secured arrears/CCJ's/defaults, this figure will change). Adverse over 12 months is ignored. Ignore all under £300 and anything settled under £3000.",
        "Defaults": "Up to 2 defaults in the last 12 months (if the customer has secured arrears/CCJ's/defaults, this figure will change). Adverse over 12 months is ignored. Ignore all under £300 and anything settled under £3000.",
        "Missed Secured Payment": "Up to 2 missed secured payments in the last 12 months (if the customer has secured arrears/CCJ's/Defaults, this figure will change). Adverse over 12 months is ignored.",
        "Missed Unsecured Payment": "",
        "Bankruptcy": "",
        "IVA": "",
        "DMP": "",
        "Payday Loans": "Maximum of 1 active payday loan, up to 3 settled in the last 12 months.",
        "Repossession": "",
        "Other": "Everything based on 'demerits' - a default or CCJ or secured arrears in the last 12 months counts as a demerit. Up to 2 demerits in the last 12 months is allowed."
    },
    "The Mortgage Lender" : {
        "LTV": "Minimum 15% deposit.",
        "CCJ": "Up to 3 CCJs in the last 36 months. None in the last 6 months.",
        "Defaults": "Up to 4 defaults in the last 36 months. 0 in the last 6 months.",
        "Missed Secured Payment": "Up to 4 missed secured payments in the last 24 months. 0 in the last 12 months.",
        "Missed Unsecured Payment": "Up to 6 missed unsecured payments in the last 24 months.",
        "Bankruptcy": "",
        "IVA": "",
        "DMP": "DMP requires satisfactory conduct for 12 months at least.",
        "Payday Loans": "Payday loan must be satisfied for at least 12 months.",
        "Repossession": "",
        "Other": ""
    },
    "Precise" : {
        "LTV": "Minimum 25% deposit.",
        "CCJ": "No CCJs in the last 24 months.",
        "Defaults": "No defaults in the last 24 months.",
        "Missed Secured Payment": "Max 1 missed secured payment in the last 36 months.",
        "Missed Unsecured Payment": "Missed unsecured payments not counted but can affect score.",
        "Bankruptcy": "",
        "IVA": "",
        "DMP": "",
        "Payday Loans": "Payday loans reviewed case by case.",
        "Repossession": "",
        "Other": ""
    },
    "Pepper Money" : {
        "LTV": "Minimum 15% deposit.",
        "CCJ": "CCJs have no value limit and do not need to be settled. None withing the past 6 months.",
        "Defaults": "Defaults have no value limit and do not need to be settled. None withing the past 6 months.",
        "Missed Secured Payment": "No missed secured payments in last 6 months.",
        "Missed Unsecured Payment": "Missed unsecured payments may be considered for revolving credit.",
        "Bankruptcy": "",
        "IVA": "",
        "DMP": "DMP must be in place for at least 12 months with satisfactory conduct.",
        "Payday Loans": "Payday loans accepted if the account has been closed for 12 months.",
        "Repossession": "",
        "Other": ""
    },
    "Foundation" : {
        "LTV": "Minimum 15% deposit.",
        "CCJ": "No CCJs in last 12 months and must be satisfied unless under £200.",
        "Defaults": "No defaults in last 12 months and must be satisfied unless under £200.",
        "Missed Secured Payment": "",
        "Missed Unsecured Payment": "Maximum of 2 missed unsecured payments per account in the last 24 months.",
        "Bankruptcy": "",
        "IVA": "",
        "DMP": "No active DMPs, has to be settled for at least 12 months.",
        "Payday Loans": "No payday loans in last 12 months.",
        "Repossession": "",
        "Other": ""
    },
    "Kent Reliance" : {
        "LTV": "Minimum 25% deposit.",
        "CCJ": "No CCJs in the last 24 months. Must be satisfied.",
        "Defaults": "No defaults in the last 24 months. Must be satisfied.",
        "Missed Secured Payment": "No missed secured payments in the last 12 months. Must be up to date.",
        "Missed Unsecured Payment": "Maximum of 2 missed unsecured payments in the last 12 months. Must be up to date.",
        "Bankruptcy": "",
        "IVA": "",
        "DMP": "",
        "Payday Loans": "",
        "Repossession": "",
        "Other": "Total aggregate balance must be under £500."
    },
    "Aldermore" : {
        "LTV": "Minimum 20% deposit.",
        "CCJ": "CCJs can unsatisfied up to a combined max of £500, none in last 36 months.",
        "Defaults": "Up to 1 default settled in the last 13-36 months (up to £500).",
        "Missed Secured Payment": "1 missed secured payment in the last 12 months, up to 2 in the last 24 months and 0 in the last 3 months.",
        "Missed Unsecured Payment": "0 missed unsecured payments in the last 3 months, maximum of 2 in the last 24 months.",
        "Bankruptcy": "",
        "IVA": "",
        "DMP": "DMP must have been conducted satisfactorily for at least 12 months.",
        "Payday Loans": "",
        "Repossession": "",
        "Other": ""
    },
    "Kensington" : {
        "LTV": "Minimum 10% deposit.",
        "CCJ": "No CCJs within last 36 months. If satisfied, acceptable. If unsatisfied, underwriter discretion.",
        "Defaults": "",
        "Missed Secured Payment": "",
        "Missed Unsecured Payment": "Up to 2 missed unsecured payments in the last 12 months. Has to be up to date.",
        "Bankruptcy": "",
        "IVA": "",
        "DMP": "DMP has to be in place for at least 12 months.",
        "Payday Loans": "Accepts payday loans in the last 6 months.",
        "Repossession": "",
        "Other": ""
    },
    "Bluestone" : {
        "LTV": "Minimum 15% deposit.",
        "CCJ": "No CCJs in last 6 months. CCJs more than 36 months ago ignored. All CCJs under £300 ignored.",
        "Defaults": "Up to 4 defaults registered in the last 36 months.",
        "Missed Secured Payment": "No missed secured payments in the last 12 months, up to 4 missed payments between 13-24 months.",
        "Missed Unsecured Payment": "If missed unsecured payment above £500, latest payment has to have been made. Max of 2 missed payments per credit agreement in the last 6 months.",
        "Bankruptcy": "",
        "IVA": "",
        "DMP": "DMP considered subject to satisfactory conduct and has to be due to a life event.",
        "Payday Loans": "No payday loans in the last 12 months. Has to be cleared prior to completion.",
        "Repossession": "",
        "Other": ""
    },
    "Vida Homeloan" : {
        "LTV": "Minimum 15% deposit.",
        "CCJ": "Maximum 4 CCJs or defaults in the last 24 months, no more than £500 in the last 6 months. Up to £5000 unsettled.",
        "Defaults": "Maximum 4 CCJs or defaults in the last 24 months, no more than £500 in the last 6 months. Up to £5000 unsettled.",
        "Missed Secured Payment": "No missed secured payments in last 6 months, max of 3 in last 24 months.",
        "Missed Unsecured Payment": "Up to 3 missed unsecured payments in the last 6 months. Up to £500.",
        "Bankruptcy": "",
        "IVA": "",
        "DMP": "DMPs considered subject to satisfactory conduct.",
        "Payday Loans": "Payday loans considered case-by-case.",
        "Repossession": "",
        "Other": ""
    },
    "Halifax" : {
        "LTV": "Minimum 5% deposit.",
        "CCJ": "",
        "Defaults": "",
        "Missed Secured Payment": "",
        "Missed Unsecured Payment": "",
        "Bankruptcy": "",
        "IVA": "",
        "DMP": "Application with DMPs comes down to credit score.",
        "Payday Loans": "",
        "Repossession": "",
        "Other": "No strict rules. Down to credit score."
    },
    "Scottish Widows" : {
        "LTV": "Minimum 10% deposit.",
        "CCJ": "No defaults or CCJs.",
        "Defaults": "No defaults or CCJs.",
        "Missed Secured Payment": "",
        "Missed Unsecured Payment": "",
        "Bankruptcy": "Bankruptcy and IVAs must be discharged more than 6 years ago.",
        "IVA": "Bankruptcy and IVAs must be discharged more than 6 years ago.",
        "DMP": "",
        "Payday Loans": "",
        "Repossession": "",
        "Other": ""
    },
    "Accord" : {
        "LTV": "Minimum 5% deposit.",
        "CCJ": "No CCJ's registered in the last 6 years. All have to be satisfied.",
        "Defaults": "No defaults registered in the last 6 years.",
        "Missed Secured Payment": "No missed secured or unsecured payments in the last 24 months.",
        "Missed Unsecured Payment": "No missed secured or unsecured payments in the last 24 months.",
        "Bankruptcy": "Bankruptcy, IVAs and DMPs must be discharged more than 6 years ago and with satisfactory conduct.",
        "IVA": "Bankruptcy, IVAs and DMPs must be discharged more than 6 years ago and with satisfactory conduct.",
        "DMP": "Bankruptcy, IVAs and DMPs must be discharged more than 6 years ago and with satisfactory conduct.",
        "Payday Loans": "",
        "Repossession": "No repossessions in the last 6 years.",
        "Other": ""
    },
    "Barclays" : {
        "LTV": "Minimum 5% deposit.",
        "CCJ": "No more than 1 CCJ in the last 3 years. Max £200. Has to be satisfied.",
        "Defaults": "Max 3 defaults. Max £200 total. Have to be satisifed.",
        "Missed Secured Payment": "Max 2 missed secured payments in the last 24 months. None in the last 6 months.",
        "Missed Unsecured Payment": "Max 1 missed unsecured payments in the last 6 months, max 2 in the last 24 months.",
        "Bankruptcy": "",
        "IVA": "",
        "DMP": "",
        "Payday Loans": "Payday loans reviewed on case by case basis.",
        "Repossession": "",
        "Other": ""
    },
    "Nationwide" : {
        "LTV": "Minimum 5% deposit.",
        "CCJ": "No set criteria for CCJs or defaults, down to underwriter discretion.",
        "Defaults": "No set criteria for CCJs or defaults, down to underwriter discretion.",
        "Missed Secured Payment": "Max of 3 missed secured payments in the last 3 years.",
        "Missed Unsecured Payment": "",
        "Bankruptcy": "Bankruptcy, IVAs and DMPs have to be discharged for at least 3 years.",
        "IVA": "Bankruptcy, IVAs and DMPs have to be discharged for at least 3 years.",
        "DMP": "Bankruptcy, IVAs and DMPs have to be discharged for at least 3 years.",
        "Payday Loans": "No payday loans in the last 12 months.",
        "Repossession": "",
        "Other": ""
    },
    "NatWest" : {
        "LTV": "Minimum 5% deposit.",
        "CCJ": "",
        "Defaults": "",
        "Missed Secured Payment": "",
        "Missed Unsecured Payment": "",
        "Bankruptcy": "No bankruptcy in the last 6 years.",
        "IVA": "No IVA's in the last 6 years.",
        "DMP": "",
        "Payday Loans": "",
        "Repossession": "",
        "Other": "No set rules, down to credit score and underwriter discretion."
    },
    "Coventry" : {
        "LTV": "Minimum 5% deposit.",
        "CCJ": "CCJs accepted if less than £500 satisfied or £250 unsatisfied. Not applicable if first time buyer. CCJs over 36 months ago considered on a case-by-case basis.",
        "Defaults": "Defaults in the least 3 years on mortgage, secured loans & unsecured loans. Less than £500 satisfied. First time buyers considered on an individual basis. Defaults in the last 3 years on communications, mail order, credit/store cards considered if less than £500 satisfied or £250 unsatisfied. First time buyers are considered on an individual basis.",
        "Missed Secured Payment": "Defaults over 3 years on mortgages, secured loans & unsecured loans. Less than £1,500 satisfied or not, may be considered on an individual basis. First time buyers considered if less than £500 satisfied or not, may be considered on an individual basis.",
        "Missed Unsecured Payment": "Defaults over 3 years on Communications, mail order, credit/store cards. Less than £1,500 satisfied or not, may be considered on an individual basis. 1 months arrears in payments allowed in the last 3 years.",
        "Bankruptcy": "Bankruptcy, IVAs and DMPs not acceptable for first time buyers.",
        "IVA": "Bankruptcy, IVAs and DMPs not acceptable for first time buyers.",
        "DMP": "Bankruptcy, IVAs and DMPs not acceptable for first time buyers.",
        "Payday Loans": "Payday loans all subject to underwriting.",
        "Repossession": "",
        "Other": ""
    },
    "Santander" : {
        "LTV": "Minimum 5% deposit.",
        "CCJ": "",
        "Defaults": "",
        "Missed Secured Payment": "No bankruptcies, IVAs, DMPs or missed secured payments.",
        "Missed Unsecured Payment": "Payday loans and missed unsecured payments considered on a case-by-case basis.",
        "Bankruptcy": "No bankruptcies, IVAs, DMPs or missed secured payments.",
        "IVA": "No bankruptcies, IVAs, DMPs or missed secured payments.",
        "DMP": "No bankruptcies, IVAs, DMPs or missed secured payments.",
        "Payday Loans": "Payday loans and missed unsecured payments considered on a case-by-case basis.",
        "Repossession": "",
        "Other": ""
    },
    "Skipton" : {
        "LTV": "Minimum 5% deposit.",
        "CCJ": "Max of 1 CCJ in the last 3 years. Max of £500. Has to be satisfied for at least 1 year.",
        "Defaults": "2 defaults no more than £500 each within the last three years (satisfied and over one year old).",
        "Missed Secured Payment": "No more than 1 missed secured payment within the last 12 months with the latest 6 months being up to date, and not to have exceeded 2 months in the last 2 years.",
        "Missed Unsecured Payment": "Max of 2 missed unsecured payments in the last 24 months.",
        "Bankruptcy": "Bankruptcies and IVAs not generally acceptable but a referral can be considered if the bankruptcy or IVA has been discharged for at least 4 years.",
        "IVA": "Bankruptcies and IVAs not generally acceptable but a referral can be considered if the bankruptcy or IVA has been discharged for at least 4 years.",
        "DMP": "Where credit bureau information shows existence of DMP’s (debt management plans), an applicaton can be considered providing this has been cleared at least 4 years previously.",
        "Payday Loans": "Where current payday loans exist within the last 3 years the application will be declined.",
        "Repossession": "Repossessions can be considered if it's over 4 years ago. Down to underwriter discretion.",
        "Other": ""
    },
    "Leeds BS" : {
        "LTV": "Minimum 5% deposit.",
        "CCJ": "Maximum of one CCJ or default in the last 3 years which must have been satisfied and be no greater than £500 in value. For anything older than 3 years, max of 4 with £20,000 balances in total.",
        "Defaults": "Maximum of one CCJ or default in the last 3 years which must have been satisfied and be no greater than £500 in value. For anything older than 3 years, max of 4 with £20,000 balances in total.",
        "Missed Secured Payment": "A maximum of 1 missed mortgage/secured loan payment in the last 12 months.",
        "Missed Unsecured Payment": "No more than 2 months arrears on any credit agreement in the last 24 months.",
        "Bankruptcy": "",
        "IVA": "",
        "DMP": "",
        "Payday Loans": "Payday loans are subject to underwriting, ensuring they are satisfied with clients reason for using them and if highlights living beyond their means.",
        "Repossession": "No previous property taken into possession within the last 6 years.",
        "Other": ""
    },
    "HSBC" : {
        "LTV": "Minimum 5% deposit.",
        "CCJ": "Will refuse any CCJ's with a total value over £500 in the last 3 years.",
        "Defaults": "No specific rules for defaults, down to credit score but highly likely to fail.",
        "Missed Secured Payment": "Max 2 missed secured payments in the last 24 months.",
        "Missed Unsecured Payment": "Max 2 missed unsecured payments in the last 24 months.",
        "Bankruptcy": "Bankruptcies, IVAs and DMPs must have been discharged for at least 3 years.",
        "IVA": "Bankruptcies, IVAs and DMPs must have been discharged for at least 3 years.",
        "DMP": "Bankruptcies, IVAs and DMPs must have been discharged for at least 3 years.",
        "Payday Loans": "",
        "Repossession": "Repossession must be cleared for at least 3 years. Will depend on credit score and may be declined.",
        "Other": "Applicants who have previously missed payments on their credit commitments will usually be declined. Individual cases may be considered under exceptional circumstances."
    },
    "Platform" : {
        "LTV": "Minimum 5% deposit.",
        "CCJ": "Will decline anyone that has any CCJ's (even satisfied) in the last 6 years for more than £100 or any unsatisfied CCJ's.",
        "Defaults": "Will decline anyone who has any defaults(s) registered in the last 12 months or has any defaults(s) during the last 6 years totalling more than £500.",
        "Missed Secured Payment": "Allowed up to 1 missed secured payment in the last 12 months.",
        "Missed Unsecured Payment": "Allowed up to 2 missed unsecured payments within the last 12 months.",
        "Bankruptcy": "",
        "IVA": "",
        "DMP": "",
        "Payday Loans": "No payday loans or reposessions.",
        "Repossession": "No payday loans or reposessions.",
        "Other": ""
    },
    "Virgin Money" : {
        "LTV": "Minimum 5% deposit.",
        "CCJ": "Will decline any CCJ whether satisfied or unsatisfied.",
        "Defaults": "Will decline if default detected.",
        "Missed Secured Payment": "Recurring missed payments will result in a decline.",
        "Missed Unsecured Payment": "Recurring missed payments will result in a decline.",
        "Bankruptcy": "Detection of bankruptcy, IVAs or DMPs will result in a decline.",
        "IVA": "Detection of bankruptcy, IVAs or DMPs will result in a decline.",
        "DMP": "Detection of bankruptcy, IVAs or DMPs will result in a decline.",
        "Payday Loans": "",
        "Repossession": "No repossessions.",
        "Other": ""
    },
    "TSB" : {
        "LTV": "Minimum 5% deposit.",
        "CCJ": "Will Decline any outstanding CCJ over £100 or any satisfied CCJ over £500 in the last 6 years.",
        "Defaults": "Automatic decline if satisfied or unsatisfied default greater than £100 in the last 12 months, satisfied or unsatisfied default greater than £500 in the last 3 years, or 2 or more satisfied or unsatisfied defaults with the latest in the last 3 years.",
        "Missed Secured Payment": "No missed secured payment over £100 in the last 12 months.",
        "Missed Unsecured Payment": "No more than 2 missed unsecured payments in the last 6 months.",
        "Bankruptcy": "",
        "IVA": "",
        "DMP": "",
        "Payday Loans": "",
        "Repossession": "",
        "Other": ""
    },
    "Clydesdale Bank" : {
        "LTV": "Minimum 10% deposit.",
        "CCJ": "Normally decline if CCJ shown on credit file.",
        "Defaults": "Normally decline if default shown on credit file.",
        "Missed Secured Payment": "Will decline any arrears in the last 36 months.",
        "Missed Unsecured Payment": "Will decline any arrears in the last 36 months.",
        "Bankruptcy": "",
        "IVA": "",
        "DMP": "",
        "Payday Loans": "Likely to decline if payday loans shown on credit file.",
        "Repossession": "",
        "Other": ""
    },
    "Metro Bank" : {
        "LTV": "Minimum 20% deposit.",
        "CCJ": "If CCJ registered in the last 2 years and satisfied, up to £1000 allowed but credit file can't show more than 2 missed payments in the last 24 months. If CCJ registered in the last 2 years and unsatisfied, up to £500 allowed BUT credit file can't show more than 2 missed payments in the last 24 months. CCJ's registered older than 2 years ago can be considered up to £1k if satisified and £500 if unsatisfied.",
        "Defaults": "If default registered in the last 2 years and satisfied or unsatisfied, up to £1000 allowed but credit file can't show more than 2 missed payments in the last 24 months. Defaults registered older than 2 years ago can be considered up to £1k if satisified or unsatisfied.",
        "Missed Secured Payment": "Up to 2 months arrears in last 24 months.",
        "Missed Unsecured Payment": "Up to 2 months arrears in last 24 months.",
        "Bankruptcy": "Bankruptcy must be discharged and more than 3 years must have passed since bankruptcy order.",
        "IVA": "Any satisfied IVA.",
        "DMP": "Accepted if Debt Management Plan/Debt Relief Order completed.",
        "Payday Loans": "Automatic decline if payday loan detected on credit file.",
        "Repossession": "6 years must have passed since repossession.",
        "Other": ""
    },
    "Dudley BS" : {
        "LTV": "Minimum 10% deposit.",
        "CCJ": "A maximum value of CCJs registered more than three years prior to application of £5,000 and a maximum number of 2 instances. CCJs must have been satisfied at least six months prior to application. No less than 20% deposit.",
        "Defaults": "A maximum value of defaults registered within three years of application (whether satisfied or not) of £1,000 and a maximum number of three instances (no less than 20% deposit). Three or fewer communication supplier defaults of up to £150 each may be ignored for the purpose of calculation value under points 3 and 4 above (where an applicant has four or more, all of them will be taken into account).",
        "Missed Secured Payment": "No missed secured payments in the last 12 months. No more than 2 in the last 24 months (no less than 20% deposit). No current arrears.",
        "Missed Unsecured Payment": "No more than 1 missed unsecured payment in the last 12 months. No more than 2 in the last 24 months (no less than 20% deposit). No current arrears.",
        "Bankruptcy": "Bankruptcies, IVAs and DPMs have to be discharged for at least 3 years (no less than 20% deposit).",
        "IVA": "Bankruptcies, IVAs and DPMs have to be discharged for at least 3 years (no less than 20% deposit).",
        "DMP": "Bankruptcies, IVAs and DPMs have to be discharged for at least 3 years (no less than 20% deposit).",
        "Payday Loans": "No more than 2 payday loan agreements being raised within the last three years. No new loans taken in the last 6 months. All loans have been settled.",
        "Repossession": "Not being subject to a previous property repossessed within the last 6 years.",
        "Other": ""
    },
    "Bank of Ireland" : {
        "LTV": "Minimum 5% deposit.",
        "CCJ": "Automatice Decline if CCJ in the last three years.",
        "Defaults": "Any defaults in the last three years is a decline. Any outstanding defaults is a decline. Settled defaults over three years is down to credit score.",
        "Missed Secured Payment": "Any missed secured payments in the last 3 years is an automatic decline.",
        "Missed Unsecured Payment": "Automatic decline if more than two consecutive missed unsecured payments on credit/store cards.",
        "Bankruptcy": "Bankruptcy, IVAs and DPMs are an automatic decline.",
        "IVA": "Bankruptcy, IVAs and DPMs are an automatic decline.",
        "DMP": "Bankruptcy, IVAs and DPMs are an automatic decline.",
        "Payday Loans": "No payday loans in the last 12 months. Beyond that, down to underwriter discretion.",
        "Repossession": "Reposession is an automatic decline.",
        "Other": ""
    },
    "West Brom" : {
        "LTV": "Minimum 5% deposit.",
        "CCJ": "Any CCJ's over £500 in the last 3 years will be an automatic decline.",
        "Defaults": "",
        "Missed Secured Payment": "Maximum of 2 payment arrears in the last 24 months.",
        "Missed Unsecured Payment": "Maximum of 2 payment arrears in the last 24 months.",
        "Bankruptcy": "Bankruptcies, IVAs and DMPs have to be discharged for at least 3 years.",
        "IVA": "Bankruptcies, IVAs and DMPs have to be discharged for at least 3 years.",
        "DMP": "Bankruptcies, IVAs and DMPs have to be discharged for at least 3 years.",
        "Payday Loans": "Can consider payday loans, though not within last 12 months.",
        "Repossession": "Repossession must be more than 6 years ago.",
        "Other": ""
    },
    "Nottingham BS" : {
        "LTV": "Minimum 5% deposit.",
        "CCJ": "A single satisfied CCJ up to £500 may be considered. CCJs above £500 must be satisfied for at least three years.",
        "Defaults": "Defaults above £500 must be satisfied for at least three years.",
        "Missed Secured Payment": "Up to two missed secured payments in the last two years. Have to be up to date.",
        "Missed Unsecured Payment": "Up to two missed unsecured payments in the last two years. Have to be up to date.",
        "Bankruptcy": "Bankruptcy, IVAs and DMPs have to be discharged for at least 3 years.",
        "IVA": "Bankruptcy, IVAs and DMPs have to be discharged for at least 3 years.",
        "DMP": "Bankruptcy, IVAs and DMPs have to be discharged for at least 3 years.",
        "Payday Loans": "Payday loans must be satisfied and not within the last 6 months.",
        "Repossession": "Repossession needs to be clear for at least 6 years.",
        "Other": ""
    },
    "Principality" : {
        "LTV": "Minimum 10% deposit.",
        "CCJ": "Automatic decline if had 1 or more CCJs in the last 6 years with a total value greater than £500.",
        "Defaults": "Automatic decline if had 1 or more defaults in the last 6 years with a total value greater than £500.",
        "Missed Secured Payment": "Max of 2 missed secured or unsecured payments in the last 2 years.",
        "Missed Unsecured Payment": "Max of 2 missed secured or unsecured payments in the last 2 years.",
        "Bankruptcy": "",
        "IVA": "",
        "DMP": "",
        "Payday Loans": "If had a payday loan in the last 12 months, unlikely they will be able to lend.",
        "Repossession": "",
        "Other": ""
    },
    "Newbury" : {
        "LTV": "Minimum 15% deposit.",
        "CCJ": "All CCJs and defaults have to be settled for at least 2 years. Multiple CCJs or defaults is likely to be declined.",
        "Defaults": "All CCJs and defaults have to be settled for at least 2 years. Multiple CCJs or defaults is likely to be declined. If only a small default on mobile phone or utility bill, can be considered.",
        "Missed Secured Payment": "An occassional missed payment may be considered but multiple will likely be declined.",
        "Missed Unsecured Payment": "An occassional missed payment may be considered but multiple will likely be declined.",
        "Bankruptcy": "Bankruptcy must have been discharged for at least 5 years.",
        "IVA": "IVAs and DMPs have to have been discharged for at least 3 years.",
        "DMP": "IVAs and DMPs have to have been discharged for at least 3 years.",
        "Payday Loans": "No payday loans in the last 3 years.",
        "Repossession": "",
        "Other": ""
    },
    "Newcastle BS" : {
        "LTV": "Minimum 15% deposit.",
        "CCJ": "Any CCJ or default registered in the last 3 years is an automatic decline. If CCJ or default was registered over 3 years ago and is satisfied then may consider.",
        "Defaults": "Any CCJ or default registered in the last 3 years is an automatic decline. If CCJ or default was registered over 3 years ago and is satisfied then may consider.",
        "Missed Secured Payment": "History of arrears will result in a decline.",
        "Missed Unsecured Payment": "History of delinquency in the last 3 years will result in a decline.",
        "Bankruptcy": "Bankruptcies and IVAs must be satisfied for at least 3 years.",
        "IVA": "Bankruptcies and IVAs must be satisfied for at least 3 years.",
        "DMP": "DMPs must be satisfied for at least 1 year.",
        "Payday Loans": "Will accept a payday loan within the last 6 months. Frequent use will be an automatic decline.",
        "Repossession": "",
        "Other": ""
    },
    "Marsden BS" : {
        "LTV": "Minimum 20% deposit.",
        "CCJ": "CCJs/Defaults within the last 3 years are not acceptable. CCJs or defaults over 3 years prior to application are acceptable up to a maximum £2,500, subject to an explanation and being cleared/satisfied 12 months prior to application. No other adverse entries must show on credit file.",
        "Defaults": "CCJs/Defaults within the last 3 years are not acceptable. CCJs or defaults over 3 years prior to application are acceptable up to a maximum £2,500, subject to an explanation and being cleared/satisfied 12 months prior to application. No other adverse entries must show on credit file.",
        "Missed Secured Payment": "Mortgage, Loan and HP arrears and missed payments at least 3 years prior to application, status 1s and 2s are accepted – status 3 or above not accepted.",
        "Missed Unsecured Payment": "Credit Card, Mail Order, Utility commitments more than 3 years prior to application – Up to and including status 2s are acceptable.",
        "Bankruptcy": "Bankruptcies, IVAs and DMPs are an automatic decline.",
        "IVA": "Bankruptcies, IVAs and DMPs are an automatic decline.",
        "DMP": "Bankruptcies, IVAs and DMPs are an automatic decline.",
        "Payday Loans": "Payday loans are reviewed on a case-by-case basis.",
        "Repossession": "",
        "Other": ""
    },
    "Market Harborough" : {
        "LTV": "Minimum 25% deposit.",
        "CCJ": "",
        "Defaults": "",
        "Missed Secured Payment": "",
        "Missed Unsecured Payment": "",
        "Bankruptcy": "",
        "IVA": "",
        "DMP": "",
        "Payday Loans": "",
        "Repossession": "",
        "Other": ""
    },
    "Ipswich BS" : {
        "LTV": "Minimum 10% deposit.",
        "CCJ": "For applications where the deposit is greater than 25% we will ignore CCJs made more than 1 year ago which has since been satisfied. For applications where the deposit is under 25% we will consider CCJs up to £500 made over 12 months ago but now satisfied. Multiple CCJs that equate to over £1,000 may not be acceptable so please check this with the sales team. The same applies for defaults.",
        "Defaults": "For applications where the deposit is greater than 25% we will ignore CCJs made more than 1 year ago which has since been satisfied. For applications where the deposit is under 25% we will consider CCJs up to £500 made over 12 months ago but now satisfied. Multiple CCJs that equate to over £1,000 may not be acceptable so please check this with the sales team. The same applies for defaults.",
        "Missed Secured Payment": "0 missed secured payments in the last 12 months.",
        "Missed Unsecured Payment": "0 missed unsecured payments in the last 12 months. Could potentially ignore telecommunications adverse but this is dependant on the amount it was for, when it occured and the deposit so please contact us to discuss.",
        "Bankruptcy": "The application can be accepted if bankruptcy discharge was more than 3 years ago and no further court orders have been registered.",
        "IVA": "To be able to accept an application the IVA or DMP must have been satisfied for 3 years.",
        "DMP": "To be able to accept an application the IVA or DMP must have been satisfied for 3 years.",
        "Payday Loans": "Payday loans considered on a case-by-case basis.",
        "Repossession": "Repossessions considered if made more than 3 years ago and if the lender incurred a shortfall debt that has been repaid in full.",
        "Other": ""
    },
    "Hinckley and Rugby" : {
        "LTV": "Minimum 5% deposit.",
        "CCJ": "CCJs will be considerd if satisfied over 3 years ago, registered 5 years ago and for an amount of no more than £500 with a minimum of 25% deposit.",
        "Defaults": "Can consider applicants with satisfied defaults up to a maximum value of £500. Has to be satisfied for at least 2 years. Will consider small, satisfied defaults on mail order and telecommunications accounts.",
        "Missed Secured Payment": "Will consider no more than 1 month's arrears in secured payments in the last 2 years with a minimum of 25% deposit.",
        "Missed Unsecured Payment": "Will consider no more than two months arrears in unsecured payments the last 2 years.",
        "Bankruptcy": "Will consider an applicant where they have been discharged from bankruptcy for a minimum of 6 years with a minimum of 25% deposit.",
        "IVA": "Will consider on referral IVAs which have been satisfied for 3 years and registered at least 5 years ago, with a minimum of 25% deposit.",
        "DMP": "Will consider satisfied debt management plans, that have been satisfied for 3 years ago with a minimum of 25% deposit. Credit since must be clear.",
        "Payday Loans": "Will only consider historic pay day loans taken over 3 years ago and with a minimum of 25% deposit. They must have been repaid on time and a satisfactory explanation will be required.",
        "Repossession": "Does not accept applicants that have been previously repossessed.",
        "Other": ""
    },
    "Harpenden BS" : {
        "LTV": "Minimum 20% deposit.",
        "CCJ": "Where any CCJ has been satisfied within the last 3 years, the value (combined if more than 1) must not exceed £500. Can potentially ignore any CCJs that were registered over 36 months ago. May consider applicants with up to 2 satisfied CCJs.",
        "Defaults": "Defaults of £500 or more have to have been settled for at least 3 years. Defaults of less than £500 have to have been settled for at least 12 months. The Society would require a satisfactory explanation of the circumstances leading to the default and a clear credit history would need to have been maintained for the last 12 months.",
        "Missed Secured Payment": "Can consider an applicant who has had up to 2 months missed payments (whether secured or unsecured) in the last 2 years.",
        "Missed Unsecured Payment": "Can potentially ignore telecommunications adverse, provided it is limited to a single account, has not resulted in a CCJ or other debt management plan, is for a small total amount, and the account has been brought back up to date at the point of application.",
        "Bankruptcy": "Can consider discharged bankrupts provided that they have been discharged more than 36 months ago and they have maintained a clean credit profile in the last 36 months.",
        "IVA": "Can consider applicants who have had an IVA registered against them, provided it was satisfied/Discharged at least 3 years ago. A satisfactory explanation should also be provided. A clear credit history since it was satisfied/discharged should also be demonstrated.",
        "DMP": "Can consider debt management plans provided that they have been resolved more than 36 months ago and they have maintained a clean credit profile in the last 36 months.",
        "Payday Loans": "Can potentially accept applicants with a previous payday loan, provided it was fully satisfied over 12 months ago, no further payday loans have been taken since, they have a clear credit history for the past 12 months and satisfactory explanation is provided.",
        "Repossession": "Will consider applications where there was a historic repossession (over 6 years ago). They must have a clear credit history since.",
        "Other": ""
    },
    "Furness BS" : {
        "LTV": "Minimum 10% deposit.",
        "CCJ": "If CCJ or default over £500, has to be settled for at least 3 years.",
        "Defaults": "If CCJ or default over £500, has to be settled for at least 3 years.",
        "Missed Secured Payment": "Automatic decline on missed secured payments.",
        "Missed Unsecured Payment": "No more than 6 status 1 missed unsecured payments in the past 2 years. Less than 2 status 2 in the past 2 years.",
        "Bankruptcy": "Bankruptcies and IVAs must be discharged for at least 6 years.",
        "IVA": "Bankruptcies and IVAs must be discharged for at least 6 years.",
        "DMP": "DMPs must be discharged for at least 3 years.",
        "Payday Loans": "No payday loans in the last 12 months.",
        "Repossession": "Unlikely to accept repossessions.",
        "Other": ""
    },
    "Bath BS" : {
        "LTV": "Minimum 5% deposit.",
        "CCJ": "Can potentially consider applicants that have had CCJs registered in the past 3 months. The CCJ would have to be satisfied and for no more than £250.",
        "Defaults": "Can potentially consider applicants that have had Defaults registered more than 24 months ago and less than 36 months ago. If satisfied. Bath Building Society has no maximum limit of satisfied Defaults.",
        "Missed Secured Payment": "Will not consider applicants with any mortgage arrears on their credit file.",
        "Missed Unsecured Payment": "Can potentially accept applicants with up to 2 months unsecured arrears in the past 24 months.",
        "Bankruptcy": "",
        "IVA": "Can potentially accept IVA's if they have been satisfied for a minimum of 2 years and registered over 3 years ago.",
        "DMP": "Can potentially accept applicants with a satisfied Debt Management Plan if this has been cleared/satisfied for over 12 months.",
        "Payday Loans": "Can potentially accept settled pay day loans if the applicant has had clean credit and no pay day loans for at least 12 months.",
        "Repossession": "",
        "Other": ""
    },
    "Beverley" : {
        "LTV": "Minimum 15% deposit.",
        "CCJ": "",
        "Defaults": "",
        "Missed Secured Payment": "",
        "Missed Unsecured Payment": "",
        "Bankruptcy": "",
        "IVA": "",
        "DMP": "",
        "Payday Loans": "",
        "Repossession": "",
        "Other": ""
    },
    "MBS Lending" : {
        "LTV": "Minimum 30% deposit.",
        "CCJ": "Maximum CCJ of £6,000 in last 2 years and must be satisfied (0 in last 6 months).",
        "Defaults": "No defaults in last 3 months. Ignore anything not registered in the last 3 months.",
        "Missed Secured Payment": "Max 1 missed secured payment in the last 3 months, max of 3 in the last 12 months.",
        "Missed Unsecured Payment": "Missed unsecured payments ignored.",
        "Bankruptcy": "Accepts day 1 bankruptcy discharges.",
        "IVA": "IVAs and DMPs have to be in place for at least 6 months and conducted satisfactorily.",
        "DMP": "IVAs and DMPs have to be in place for at least 6 months and conducted satisfactorily.",
        "Payday Loans": "No more than 1 payday loan in the last 3 months. Less than 10% of the net salary, can be ignored. If more than 10% of net salary, declined. Ok if prior to last 12 months.",
        "Repossession": "Repossessions accepted if more than 2 years ago.",
        "Other": ""
    },
    "Family Building Society" : {
        "LTV": "Minimum 25% deposit.",
        "CCJ": "One County Court Judgement (CCJ), with a total value less than £500, within the last three years subject to being satisfied and a satisfactory explanation provided. The total amount of CCJs within the last three years cannot exceed £500. Multiple instances of adverse credit would not be acceptable even if over 3 years.",
        "Defaults": "Can consider applicants with up to 1 satisfied default that relate to a utility or communication supplier. Can potentially ignore any satisfied defaults that were registered over 36 months ago subject to a satisfactory explanation. Can potentially consider applicants that have had Defaults registered more than 24 months ago and less than 36 months ago. - Needs to have been satisfied for at least 24 months.",
        "Missed Secured Payment": "Will not consider applicants with any mortgage arrears in the previous 24 months.",
        "Missed Unsecured Payment": "A maximum of one month’s arrears within the last 12 months will be allowed, provided the account is currently up to date.",
        "Bankruptcy": "Can consider discharged bankrupts as long as they have been discharged at least three years, have no subsequent adverse registered credit and can provide a satisfactory explanation.",
        "IVA": "Will look at satisfied IVAs only three years from when they are satisfied. Multiple examples of adverse credit will result in applications being declined even if over this time period.",
        "DMP": "Do not accept applicants with previous DMPs, payday loans or repossessions.",
        "Payday Loans": "Do not accept applicants with previous DMPs, payday loans or repossessions.",
        "Repossession": "Do not accept applicants with previous DMPs, payday loans or repossessions.",
        "Other": ""
    },
    "Vernon BS" : {
        "LTV": "Minimum 20% deposit.",
        "CCJ": "1 or more CCJ’s with a total value greater than £500 within the last 36 months is a decline. Has to be satisfied for at least 6 months.",
        "Defaults": "Defaults must be over 2 years ago and settled for at least 2 years for less than £5000.",
        "Missed Secured Payment": "Status 3 missed payments or worse in the last 24 months is a decline. Has to be satisfied and clear for 6 months.",
        "Missed Unsecured Payment": "Status 3 missed payments or worse in the last 24 months is a decline. Has to be satisfied and clear for 6 months.",
        "Bankruptcy": "",
        "IVA": "IVAs and DMPs need to be discharged for at least 3 years & had have been to be under £10k.",
        "DMP": "IVAs and DMPs need to be discharged for at least 3 years & had have been to be under £10k.",
        "Payday Loans": "No more than 3 payday loans in any 12 month period and none within the last 3 months.",
        "Repossession": "Repossessions need to be clear for at least 5 years.",
        "Other": ""
    },
    "Loughborough" : {
        "LTV": "Minimum 30% deposit.",
        "CCJ": "Up to 3 CCJ’s, satisfied or unsatisfied, totalling a combined maximum of £1,000. Main focus is on the satisfaction date.",
        "Defaults": "Defaults satisfied or unsatisfied within the last two years with a full explanation of the facts.",
        "Missed Secured Payment": "Worst missed payment status 3-6 months in last two years.",
        "Missed Unsecured Payment": "Worst missed payment status 3-6 months in last two years.",
        "Bankruptcy": "Bankruptcy muse be discharged for more than 3 years.",
        "IVA": "Applicants currently in an IVA or DMP must have conducted the arrangement satisfactorily for a minimum of 2 years prior to the date of application.",
        "DMP": "Applicants currently in an IVA or DMP must have conducted the arrangement satisfactorily for a minimum of 2 years prior to the date of application.",
        "Payday Loans": "Can accept 2-3 payday loans in last 12 months with a full explanation as to the reason.",
        "Repossession": "Repossessions accepted if discharged for more than 3 years.",
        "Other": ""
    },
    "Monmouthshire" : {
        "LTV": "Minimum 5% deposit.",
        "CCJ": "No more than £500.00 of CCJs which must have been satisfied over 1 year ago. Registration date doesn't matter.",
        "Defaults": "No more than £500.00 of defaults which must have been satisfied over 1 year ago.",
        "Missed Secured Payment": "Missed secured payments are acceptable providing that this was over 3 years ago and no more than 2 payments were missed.",
        "Missed Unsecured Payment": "Can consider 1 missed unsecured payment up to a status 2 in the last 24 months but no missed payments in the last 6 months or 2 status 1 allowed in the last 12 months but must be up to date at the time of application.",
        "Bankruptcy": "Bankruptcies, IVAs, DMPs and payday loans can be considerd if over 3 years ago.",
        "IVA": "Bankruptcies, IVAs, DMPs and payday loans can be considerd if over 3 years ago.",
        "DMP": "Bankruptcies, IVAs, DMPs and payday loans can be considerd if over 3 years ago.",
        "Payday Loans": "Bankruptcies, IVAs, DMPs and payday loans can be considerd if over 3 years ago.",
        "Repossession": "Repossessions not accepted.",
        "Other": ""
    },
    "Chorley" : {
        "LTV": "Minimum 20% deposit.",
        "CCJ": "No more than 1 CCJ within the last 3 years up to a value of £500 that is not connected with a mortgage, loan or revolving credit e.g. parking fine. All CCJs have been registered and settled more than 3 years ago.",
        "Defaults": "No more than 2 defaults within the last 2 years for less than £500 in total that are not connected with a mortgage, loan or revolving credit e.g. Utility bills or Mobile Phone airtime. Defaults settled more than 2 years ago.",
        "Missed Secured Payment": "Cumulative amount of arrears must not have reached the equivalent of 3 or more monthly payments within the last 2 years.",
        "Missed Unsecured Payment": "Cumulative amount of arrears must not have reached the equivalent of 3 or more monthly payments within the last 2 years.",
        "Bankruptcy": "Bankruptcies and IVAs greater than 3 years ago can be considered.",
        "IVA": "Bankruptcies and IVAs greater than 3 years ago can be considered.",
        "DMP": "DMP that has been settled for more than 2 years can be considered.",
        "Payday Loans": "No payday loans in the last 6 years.",
        "Repossession": "Repossession must be discharged for at least 3 years.",
        "Other": ""
    },
    "Hanley Economic" : {
        "LTV": "Minimum 20% deposit.",
        "CCJ": "CCJ with maximum total value of £1,500.00 showing on the credit report (satisfied or unsatisfied). CCJ must not have occurred in the last 12 months.",
        "Defaults": "No defaults in the last 3 months. By consideration only 3–12 months. Anything over 12 months ignored.",
        "Missed Secured Payment": "No more than 1 missed secured payment within the last 12 months. None in the last 3 months.",
        "Missed Unsecured Payment": "Maximum of 3 missed unsecured payments in the last 12 months totalling no more than £500. No missed payments in the last 3 months.",
        "Bankruptcy": "Bankruptcy must be discharged for a minimum of 6 years.",
        "IVA": "IVAs acceptable if satisfied for 12 months or more.",
        "DMP": "Applicants must not have entered into a DMP within the last 12 months. Where a DMP exists no missed payments within the last 12 months.",
        "Payday Loans": "Applicants who have entered in a payday loan agreement within the last 12 months are unacceptable.",
        "Repossession": "Repossessions not acceptable.",
        "Other": ""
    },
    "Leek Utd" : {
        "LTV": "Minimum 15% deposit.",
        "CCJ": "30% deposit if CCJ in the last 3 years (max £500). CCJ’s with an aggregate total of £500 or less, registered over 3 years ago and satisfied for at least 6 months can be considered.",
        "Defaults": "Defaults with an aggregate total of £500 or less, registered over 3 years ago and satisfied for at least 6 months can be considered.",
        "Missed Secured Payment": "30% deposit if in the last 2 years 3 months missed payments. No more than 1 missed secured payment in the last 12 months.",
        "Missed Unsecured Payment": "1 missed payment on no more than one communication supplier, credit card, mail order or utility bill for no more than one month, can be considered providing the account has been brought up to date and a satisfactory explanation is provided. No more than one over limit position on credit cards or current accounts during the last 12 months, which should have now been rectified.",
        "Bankruptcy": "Bankruptcy must be discharged more than 6 years ago and no adverse credit within last 3 years will be considered on full application.",
        "IVA": "IVAs and DMPs must be registered over 6 years ago, must have been completed over 3 years ago, no adverse credit within last 3 years and satisfactory explanation will be considered on full application.",
        "DMP": "IVAs and DMPs must be registered over 6 years ago, must have been completed over 3 years ago, no adverse credit within last 3 years and satisfactory explanation will be considered on full application.",
        "Payday Loans": "Applicants who have taken out a pay day loan within the last three years are not acceptable.",
        "Repossession": "Repossessions not acceptable.",
        "Other": ""
    },
    "Stafford Railway" : {
        "LTV": "Minimum 15% deposit.",
        "CCJ": "Any satisfied CCJ’s found on credit reports totalling less than £500 can be considered. Any satisfied CCJ over £500 must have been satisfied for 6 years.",
        "Defaults": "May approve a mortgage with a satisfied default or defaults in the following scenario: Satisfied defaults totaling no more than £500 A full explanation must be provided by the applicant(s). Defaults over £500 must have been satisfied for at least 6yrs before the Society can consider the application.",
        "Missed Secured Payment": "A maximum of three missed payments in the last 24 months, reaching no more than a status 2 at any one time. Applicants will need to be clear of any arrears for the previous 12 months.",
        "Missed Unsecured Payment": "A maximum of three missed payments in the last 24 months, reaching no more than a status 2 at any one time. Applicants will need to be clear of any arrears for the previous 12 months.",
        "Bankruptcy": "Bankruptcy must have been satisfied for at least 6 years to be considered.",
        "IVA": "IVAs and DMPs must have been satisfied for 6 years before the Society can consider the case.",
        "DMP": "IVAs and DMPs must have been satisfied for 6 years before the Society can consider the case.",
        "Payday Loans": "Payday loans must have been satisfied within the last 5 years.",
        "Repossession": "Repossessions not acceptable.",
        "Other": ""
    },
    "Scottish BS" : {
        "LTV": "Minimum 10% deposit.",
        "CCJ": "Up to two satisfied Court Decrees, CCJs and defaults will be considered based on the following - Up to £250 (can be registered within the last year); >£250 to <£500 (registered > 1 year); >£500 & <£2500 (registered > 3 years).",
        "Defaults": "Up to two satisfied Court Decrees, CCJs and defaults will be considered based on the following - Up to £250 (can be registered within the last year); >£250 to <£500 (registered > 1 year); >£500 & <£2500 (registered > 3 years).",
        "Missed Secured Payment": "Max of 2 months arrears in the last 24 months. Has to be up to date for the last 6 months.",
        "Missed Unsecured Payment": "Max of 2 months arrears in the last 24 months. Has to be up to date for the last 6 months.",
        "Bankruptcy": "Bankruptcies, IVAs and DMPs must be satisfied for at least 3 years.",
        "IVA": "Bankruptcies, IVAs and DMPs must be satisfied for at least 3 years.",
        "DMP": "Bankruptcies, IVAs and DMPs must be satisfied for at least 3 years.",
        "Payday Loans": "Occasional use of payday loans is ok but regular use would cause a problem. Payday loan settled over 3 years ago is unlikely to be a problem.",
        "Repossession": "Repossessions not acceptable.",
        "Other": ""
    },
    "Teachers BS" : {
        "LTV": "Minimum 20% deposit.",
        "CCJ": "Maximum 1 CCJ in last 3 years not exceeding £500.",
        "Defaults": "No outstanding defaults in last 2 years. Up to 1 default with max value of £100.",
        "Missed Secured Payment": "No more than 2 consecutive missed secured payments in last 2 years. None in last 3 months.",
        "Missed Unsecured Payment": "No more than 2 missed unsecured payment in the last 6 months.",
        "Bankruptcy": "No outstanding bankruptcies or IVAs in the last 3 years.",
        "IVA": "No outstanding bankruptcies or IVAs in the last 3 years.",
        "DMP": "No outstanding DMPs in the last 12 months.",
        "Payday Loans": "Payday loans not acceptable if used in the last 12 months.",
        "Repossession": "Repossession acceptable if more than 5 years ago.",
        "Other": ""
    },
    "Tipton" : {
        "LTV": "Minimum 5% deposit.",
        "CCJ": "Registered and satisfied CCJs more than three years ago will be considered, subject to a maximum three CCJ’s to a total value of £500.",
        "Defaults": "A maximum of three defaults per application, to a total value of £500, satisfied 3 months prior to application will be considered subject to a satisfactory explanation.",
        "Missed Secured Payment": "Maximum two missed secured payments within the last two years may be considered, subject to satisfactory explanation.",
        "Missed Unsecured Payment": "Within last two years late payments on a credit agreement cannot exceed a status 2 at any point i.e. max of 2 consecutive missed payments.",
        "Bankruptcy": "Bankruptcies, IVAs and DMPs must be discharged/satisfied for a minimum of six years prior to application.",
        "IVA": "Bankruptcies, IVAs and DMPs must be discharged/satisfied for a minimum of six years prior to application.",
        "DMP": "Bankruptcies, IVAs and DMPs must be discharged/satisfied for a minimum of six years prior to application.",
        "Payday Loans": "Payday loans taken out and fully repaid more than 12 months prior to application may be considered.",
        "Repossession": "Repossessions not acceptable.",
        "Other": ""
    },
    "Masthaven" : {
        "LTV": "Minimum 25% deposit.",
        "CCJ": "Up to 1 CCJ or default >£300 registered in the last 24 months. Satisfied or unsatisfied. CCJs or defaults less than £300, older than 36 months or satisfied more than 2 years ago are ignored.",
        "Defaults": "Up to 1 CCJ or default >£300 registered in the last 24 months. Satisfied or unsatisfied. CCJs or defaults less than £300, older than 36 months or satisfied more than 2 years ago are ignored.",
        "Missed Secured Payment": "Up to status 2 missed secured payments in the last 24 months.",
        "Missed Unsecured Payment": "1 unsecured credit account > £300 where the worst status is 3 or more.",
        "Bankruptcy": "Bankruptcies or IVAs must have been discharged for at least 3 years.",
        "IVA": "Bankruptcies or IVAs must have been discharged for at least 3 years.",
        "DMP": "DMPs must be discharged for at least 2 years.",
        "Payday Loans": "Payday loans considered on a case-by-case basis.",
        "Repossession": "Repossessions acceptable if over 5 years ago.",
        "Other": ""
    },
    "Digital" : {
        "LTV": "Minimum 5% deposit.",
        "CCJ": "Where there has been more than one CCJ in total, the application will be declined. Where there is one satisfied CCJ for up to £500, the application may be considered subject to satisfactory credit score.",
        "Defaults": "Where there has been more than one default in total, the application will be declined. Where there is one satisfied default for up to £500, the application may be considered where this is an isolated incident and subject to satisfactory credit score.",
        "Missed Secured Payment": "Where account has been a maximum of one month in arrears in the last 12 months, the application may be considered where this is an isolated incident and subject to satisfactory credit score. Where there has been more than one instance of arrears in total, the application will be declined.",
        "Missed Unsecured Payment": "Where account has been a maximum of one month in arrears in the last 12 months, the application may be considered where this is an isolated incident and subject to satisfactory credit score. Where there has been more than one instance of arrears in total, the application will be declined.",
        "Bankruptcy": "Bankruptcies, IVAs and DMPs must have been discharged more than 6 years ago.",
        "IVA": "Bankruptcies, IVAs and DMPs must have been discharged more than 6 years ago.",
        "DMP": "Bankruptcies, IVAs and DMPs must have been discharged more than 6 years ago.",
        "Payday Loans": "No more than 3 payday loans within the last six months providing affordability is strong, the applicant(s) shows no signs of credit pressure and subject to satisfactory credit score.",
        "Repossession": "Repossessions accepted if over 6 years ago.",
        "Other": ""
    },
    // "" : {
    //     "LTV": "",
    //     "CCJ": "",
    //     "Defaults": "",
    //     "Missed Secured Payment": "",
    //     "Missed Unsecured Payment": "",
    //     "Bankruptcy": "",
    //     "IVA": "",
    //     "DMP": "",
    //     "Payday Loans": "",
    //     "Repossession": "",
    //     "Other": ""
    // },
}

export default reasons;