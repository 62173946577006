import React, { Component } from 'react';
import "./landing_sparse.sass";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { FaWindows } from 'react-icons/fa';

const url = "https://free.us19.list-manage.com/subscribe/post?u=d07117df69703cf35c69b27be&amp;id=7cc3bafab4";

class Landing_Sparse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email_valid: false
        }
    }

    componentDidMount() {
        let c = document.querySelector("#m div").children;
        c[1].setAttribute("id", "mailchimp_submit");
        c[0].setAttribute("id", "email_field");

        let validator = require("email-validator");

        console.log(c);

        document.getElementById("mailchimp_submit").addEventListener("click", function () {
            if (this.state.email_valid) {
                setTimeout(function () {
                    window.location.href = "/thanks";
                }, 500);
            }
        }.bind(this));

        document.getElementById("email_field").addEventListener("input", function () {
            if(validator.validate(this.value)) {
                this.setState({
                    email_valid: true
                });
            }
        }.bind(this));
    }

    render() {
        return (
            <div className="landing">
                <div className="display">
                    <div className="logo_wrap">
                        <img className="logo" src={require("./img/logo.svg")} alt="Free logo" />
                    </div>

                    <div className="heading">
                        <div className="free">FREE</div>.CO.UK
                    </div>
                    <div className="mission">
                        We are making buying and selling homes simple.
                    </div>
                    <div className="heading" style={{paddingTop: "0px"}}>
                        SIMPLE IS <div className="free">BETTER</div>
                    </div>

                    <div className="content_section">
                        <div className="meantime_section">
                            <div className="heading">
                                Keep up to date
                        </div>
                            <div className="mail_chimp" id="m">
                                <MailchimpSubscribe url={url} />
                            </div>
                        </div>

                        <div className="or_hr">
                            <div className="or_divide">
                                <div className="or">
                                    OR
                            </div>
                            </div>
                        </div>

                        <div style={{ cursor: "pointer" }} className="meantime_section" onClick={() => document.getElementById("tools").scrollIntoView({ behavior: "smooth" })}>
                            <div className="heading">
                                View our tools and videos
                        </div>
                            <div className="arrow">
                                <i class="fas fa-arrow-down"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="other_section">
                    <div id="tools" className="tools">
                        <div className="heading">
                            Our other tools...
                    </div>
                        <ul>
                            <li>
                                <div className="tool" onClick={() => window.location.href = "/optimiser"}>
                                    Rightmove Optimiser
                            </div>
                            </li>
                            <li>
                                <div className="tool" onClick={() => window.location.href = "/calculator"}>
                                    Stamp Duty Calculator
                            </div>
                            </li>
                            <li>
                                <div className="tool" onClick={() => window.location.href = "/valuator"}>
                                    House Valuator
                            </div>
                            </li>
                        </ul>
                    </div>

                    <div className="videos">
                        <div className="heading">
                            Our videos...
                    </div>
                        <div className="video">
                            <iframe className="video" src="https://www.youtube.com/embed/DE9h42bT-_c" title='Runners'></iframe>
                        </div>
                        <div className="video">
                            <iframe className="video" src="https://www.youtube.com/embed/t_PZtH-COgY" title='Postman'></iframe>
                        </div>
                        <div className="video">
                            <iframe className="video" src="https://www.youtube.com/embed/SMedxSX2iWc" title='Beautiful game'></iframe>
                        </div>
                        <div className="video">
                            <iframe className="video" src="https://www.youtube.com/embed/V2-tsKguZwY" title='Life partner'></iframe>
                        </div>
                        <div className="video">
                            <iframe className="video" src="https://www.youtube.com/embed/AUiM_9cv-kg" title='Love song'></iframe>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Landing_Sparse;