import React, { useEffect, useState } from 'react';
import Selection from '../../selection';

function Bankruptcy(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case 48:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "Have you ever filed for bankruptcy?",
                        info: {
                            id: "bankruptcy_modal",
                            text: "What is bankruptcy?",
                            content: "Bankruptcy is a legal status for people who're unable to repay the money they owe.",
                            content2: "Bankruptcy is seen as a serious credit issue because it tells mortgage lenders you’ve had issues with repaying debts in the past. Even if this is no longer the case. Bankruptcy will remain on your credit file for a period of six years."
                        },
                        buttons: {
                            "Yes": {
                                value: "YES",
                                next: 49
                            },
                            "No": {
                                value: "NO",
                                next: 50
                            }
                        }
                    }} id={"been_bankrupt"} setValue={(e) => props.setValue({ ...props.store, been_bankrupt: e, ccjs: [] })} />
                )
            
            case 49:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "When were you discharged?",
                        input: {
                            type: "month",
                            id: "bankruptcy_discharged",
                            min: 7,
                            next: 50
                        },
                        sub_button: {
                            "Or mark this bankruptcy as not yet satisfied": {
                                value: "ONGOING",
                                next: 50
                            }
                        }
                    }} id={"bankruptcy_discharged"} setValue={(e) => props.setValue({ ...props.store, bankruptcy_discharged: e })} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default Bankruptcy;