import React from 'react';
import AuthModal from '../auth/auth_modal';

function Results(props) {
    return (
        <div className="adverse_flow">
            {props.auth ? <AuthModal store={props.store} show={props.auth} /> : null}
        </div>
    );
}

export default Results;