import React, { useEffect, useState } from 'react';
import Selection from '../selection';
import format_data from "../format_data"
import Results from '../results';

function AccountCreation(props) {
    const [createAccount, setCreateAccount] = useState(false)
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case 105:
                console.log(format_data(props.store))
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "Calculating your results...",
                        content: "Create an account to save your progress so far.",
                        buttons: {
                            "Create account": {
                                value: true,
                                next: 106,
                                dot: false,
                                small: true
                            }
                        },
                        content3: "Or see what you can borrow without creating an account (you’ll have to start again if you want to come back).",
                        sub_button: {
                            "See your results without saving": {
                                value: false,
                                next: 106
                            }
                        }
                    }} id={"deposit"} setValue={(e) => setCreateAccount(e)} />
                )

            case 106:
                return (
                    <Results store={props.store} auth={createAccount}/>
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default AccountCreation;