import React, { Component } from 'react';
import "./accordion.sass";

class AccordionButton extends Component {
    openAccordion(e) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        let id = document.getElementById(this.props.id);
        let btn = document.getElementById(this.props.id + "_button");
        let extra = 0;
        if(this.props.extra && document.getElementById(this.props.extra)) extra = document.getElementById(this.props.extra).scrollHeight
        if (id && id.style) {
            if (id.style.maxHeight === "0px") {
                id.style.maxHeight = id.scrollHeight + extra + "px";
                btn.innerHTML = this.props.alt;
            } else {
                id.style.maxHeight = "0px";
                btn.innerHTML = this.props.phrase;
            }
        }
    }

    render() {
        return (
            <div className="accordion_button" onClick={(e) => this.openAccordion(e)} id={this.props.id + "_button"}>
                {this.props.phrase}
            </div>
        );
    }
}

export default AccordionButton;